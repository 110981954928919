import { put, select } from "redux-saga/effects";
import { deleteProgramme } from "../../data/api/endpoints";
import { actions } from "..";

export default function* getDeleteProgrammeSaga(param) {
  try {
    const { programmeId } = param.payload;
    const resp = yield deleteProgramme(programmeId);
    if (!resp.status || resp.status === 200) {
      const state = yield select();
      yield put(actions.setFilter({
        ...state.filterData,
        noticeDisplay: true,
        noticeMessage: 'Your program deleted Successfully',
        loading: false,
        programs: state.filterData.programs.filter((program) => program.id !== programmeId)
      }));
      yield put(actions.setForm({
        ...state.formData,
        formActive:11,
      }));

    } else {
      const state = yield select();
      yield put(actions.setFilter({
        ...state.filterData,
        apiError: true,
      }));
    }
  } catch (error) {
    const state = yield select();
    yield put(actions.setFilter({
      ...state.filterData,
      apiError: true,
    }));
  }
}