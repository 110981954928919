import { put, select } from "redux-saga/effects";
import { actions } from "..";
import {
  fetchProgrammeConfig,
  downloadAssets,
  upsertProgrammeConfig,
} from "../../data/api/endpoints";
import { getSafe } from "../../data/utils/functions";
import { apiListScreens, formListScreens } from "../../data/const/validateData";
import { editorStyles } from "../../data/utils/functions";

const mapResponseToState = (resp, state) => {
  if (resp?.data) state.value = resp["data"]["label"];
  if (resp?.style) state = { ...state, ...resp["style"] };
  return state;
};

export default function* getFetchProgramConfigSaga(param) {
  try {
    const { programmeId, componentId } = param.payload;
    const resp = yield fetchProgrammeConfig(
      programmeId,
      apiListScreens[componentId]
    );
    if (!resp.status || resp.status === 200) {
      const state = yield select();
      var data = getSafe(() => resp["data"]["data"]["context"]) || "";
      switch (componentId) {
        case 1:
          state.formData.brand.colorBaground.code =
            data["globals"]["style"]["brandBackgroundColor"];
          state.formData.brand.closeBackground.code =
            data["globals"]["style"]["crossButtonBackgroundColor"];
          state.formData.brand.closeColor.code =
            data["globals"]["style"]["crossButtonTextColor"];
          state.formData.brand.logo.blob =
            data["globals"]["data"]["brandLogoBlob"];

          if (data["globals"]["data"]["brandLogoBlob"]) {
            const image = yield downloadAssets(
              data["globals"]["data"]["brandLogoBlob"]
            );
            state.formData.brand.logo.src = image
              ? URL.createObjectURL(image)
              : "";
          } else {
            state.formData.brand.logo.src = "";
          }

          state.formData.brand.loading = true;
          state.formData.brand.isLandscape =
            data.globals?.data?.isLandscape ?? false;
          break;
        case 2:
          state.formData.begin.button = mapResponseToState(
            data["beginScreenButton"],
            state.formData.begin.button
          );

          state.formData.begin.video.blob =
            data["beginScreenVideo"]["data"]["videoBlob"];
          if (data["beginScreenVideo"]["data"]["videoBlob"]) {
            const beginVideo = yield downloadAssets(
              data["beginScreenVideo"]["data"]["videoBlob"]
            );
            state.formData.begin.video.src = beginVideo
              ? URL.createObjectURL(beginVideo)
              : "";
          } else {
            state.formData.begin.video.src = "";
          }

          state.formData.begin.loading = true;
          break;
        case 3:
          state.formData.howScanWork.header = mapResponseToState(
            data["howScanWorkTitle"],
            state.formData.howScanWork.header
          );
          state.formData.howScanWork.content = mapResponseToState(
            data["howScanWorksContent"],
            state.formData.howScanWork.content
          );
          state.formData.howScanWork.checkbox = mapResponseToState(
            data["howScanWorksCheckbox"],
            state.formData.howScanWork.checkbox
          );
          state.formData.howScanWork.button = mapResponseToState(
            data["howScanWorksOkGotItButton"],
            state.formData.howScanWork.button
          );
          state.formData.howScanWork.contentBottom = mapResponseToState(
            data["howScanWorksDisclaimerContent"],
            state.formData.howScanWork.contentBottom
          );

          state.formData.howScanWork.image.blob =
            data["howScanWorkImage"]["data"]["imageBlob"];

          if (data["howScanWorkImage"]["data"]["imageBlob"]) {
            const scanImage = yield downloadAssets(
              data["howScanWorkImage"]["data"]["imageBlob"]
            );
            state.formData.howScanWork.image.src = scanImage
              ? URL.createObjectURL(scanImage)
              : "";
          } else {
            state.formData.howScanWork.image.src = "";
          }
          state.formData.howScanWork.loading = true;
          break;
        case 4:
          state.formData.termsConditions.header = mapResponseToState(
            data["termsAndConditionTitle"],
            state.formData.termsConditions.header
          );
          state.formData.termsConditions.content = mapResponseToState(
            data["termsAndConditionContent"],
            state.formData.termsConditions.content
          );
          state.formData.termsConditions.button = mapResponseToState(
            data["termsAndConditionIUnderstandButton"],
            state.formData.termsConditions.button
          );
          state.formData.termsConditions.contentBottom = mapResponseToState(
            data["termsAndConditionDisclaimerContent"],
            state.formData.termsConditions.contentBottom
          );

          state.formData.termsConditions.image.blob =
            data["termsAndConditionQRCode"]["data"]["qrCodeBlob"];

          if (data["termsAndConditionQRCode"]["data"]["qrCodeBlob"]) {
            const qrCode = yield downloadAssets(
              data["termsAndConditionQRCode"]["data"]["qrCodeBlob"]
            );
            state.formData.termsConditions.image.src = qrCode
              ? URL.createObjectURL(qrCode)
              : "";
          } else {
            state.formData.termsConditions.image.src = "";
          }

          state.formData.termsConditions.loading = true;
          break;
        case 5:
          state.formData.form.button = mapResponseToState(
            data["patientInfoNextButton"],
            state.formData.form.button
          );

          let tmp = data["patientInfoQuestion"]["data"];

          state.formData.form.question1.value = tmp["ageQuestion"];
          state.formData.form.label10.value = tmp["years"];
          state.formData.form.error10.value = tmp["ageErrorMessage"];

          state.formData.form.question2.value = tmp["weightQuestion"];
          state.formData.form.error20.value = tmp["weightErrorForKg"];
          state.formData.form.error21.value = tmp["weightErrorForLbs"];
          state.formData.form.label20.value = tmp["kg"];
          state.formData.form.label21.value = tmp["lbs"];

          state.formData.form.question3.value = tmp["heightQuestion"];
          state.formData.form.error30.value = tmp["heightErrorForCM"];
          state.formData.form.error31.value = tmp["heightErrorForFeet"];
          state.formData.form.error40.value = tmp["heightErrorForInch"];
          state.formData.form.label30.value = tmp["cm"];
          state.formData.form.label31.value = tmp["ft"];

          state.formData.form.question4.value = tmp["genderQuestion"];
          state.formData.form.label40.value = tmp["female"];
          state.formData.form.label41.value = tmp["male"];

          state.formData.form.question5.value =
            tmp["hydrationQuestion"] ?? state.formData.form.question5.value;
          state.formData.form.label42.value =
            tmp["hydrated"] ?? state.formData.form.label42.value;
          state.formData.form.label43.value =
            tmp["not_hydrated"] ?? state.formData.form.label43.value;
          state.formData.form.display = tmp["isDisplay"] || 0;
          state.formData.form.loading = true;
          break;
        case 6:
          // Do nothing
          break;
        case 7:
          state.formData.results.header = mapResponseToState(
            data["resultScreenTitle"],
            state.formData.results.header
          );
          state.formData.results.download = mapResponseToState(
            data["downloadAppButton"],
            state.formData.results.download
          );
          state.formData.results.footer = mapResponseToState(
            data["resultScreenDisclaimer"],
            state.formData.results.footer
          );
          if (data?.resultScreenNextButton) {
            state.formData.results.nextButton = mapResponseToState(
              data?.resultScreenNextButton,
              state?.formData?.results?.nextButton
            );
          }

          state.formData.results.selectedWidget = data["resultWidget"]["data"][
            "widgets"
          ].map((widget, i) => {
            let currentWidget = state.formData.results.selectedWidget?.find(
              (w) => w.code == widget.code
            );

            let indexes = currentWidget?.indexes;

            if (widget.code == "MSI") {
              let details = [];

              try {
                details = JSON.parse(
                  atob(widget["explanationLabel"]) || "W10="
                );
                state.formData.results.lowValue = {
                  ...state.formData.results.lowValue,
                  value: widget.lowLabel,
                };
                state.formData.results.highValue = {
                  ...state.formData.results.highValue,
                  value: widget.highLabel,
                };
              } catch (error) {}

              if (details?.length == 5) {
                indexes.forEach((item1, index) => {
                  const item2 = details[index];
                  item1.state = item2.state;
                  item1.explaination = item2.explaination;
                });
              }
            }

            return {
              ...currentWidget,
              id: i + 1,
              code: widget.code,
              title: widget.title,
              background: widget.background ?? "#0000001a",
              showIcon: widget.showIcon ?? false,
              type: widget.type || "graph",
              titleWidget: {
                ...currentWidget?.titleWidget,
                ...widget["titleStyle"],
                value: widget["title"],
              },
              button: {
                ...currentWidget?.button,
                ...widget["buttonStyle"],
                value: widget["buttonLabel"],
              },
              explanation: {
                ...currentWidget?.explanation,
                ...widget["explanationStyle"],
                value: widget["explanationLabel"],
              },
              explanationButton: {
                ...currentWidget?.explanationButton,
                ...widget["explanationButton"],
                value: widget["explanationButtonLabel"],
              },
              highValue: {
                value: widget?.highLabel || currentWidget?.highLabel || "",
              },
              lowValue: {
                value: widget?.lowLabel || currentWidget?.lowLabel || "",
              },
              indexes,
            };
          });
          state?.formData?.results?.selectedWidget?.sort((a, b) => a.id - b.id);
          state.formData.results.activeMSIIndex = 0;
          state.formData.results.showDownloadButton = data?.downloadAppButton
            ?.data?.isDisplay
            ? 1
            : 0;
          state.formData.results.activeForm = 0;
          state.formData.results.loading = true;
          break;
        case 8:
          let stressDetails = [];
          try {
            for (let i = 0; i < 2; i++) {
              let details = {
                title: state.formData.product.stressDetails[i].title,
                header: mapResponseToState(
                  data[`productScreenTitleIndex${i}`] ||
                    state.formData.product.stressDetails[i].header,
                  state.formData.product.stressDetails[i].header
                ),
              };
              for (let j = 1; j <= 2; j++) {
                let images = [];
                for (let k = 0; k < 3; k++) {
                  let src = "";
                  if (
                    data[`productScreenStressIndex${i}Row${j}`]["data"][
                      `image${k}`
                    ]?.blob
                  ) {
                    const image = yield downloadAssets(
                      data[`productScreenStressIndex${i}Row${j}`]["data"][
                        `image${k}`
                      ].blob
                    );
                    src = image ? URL.createObjectURL(image) : "";
                  }

                  images.push({
                    ...data[`productScreenStressIndex${i}Row${j}`]["data"][
                      `image${k}`
                    ],
                    src,
                  });
                }
                details[`row${j}`] = {
                  description: mapResponseToState(
                    data[`productScreenStressIndex${i}Row${j}`],
                    state.formData.product.stressDetails[i][`row${j}`]
                      .description
                  ),
                  images,
                };
              }
              stressDetails.push(details);
            }
          } catch (error) {
            console.log(error, "sheraziiiii");
          }
          state.formData.product.stressDetails = stressDetails;
          state.formData.product.help = mapResponseToState(
            data["productScreenHelpText"],
            state.formData.product.help
          );
          state.formData.product.primaryButton = mapResponseToState(
            data["productScreenPrimaryButton"],
            state.formData.product.primaryButton
          );
          state.formData.product.secondaryButton = mapResponseToState(
            data["productScreenSecondaryButton"],
            state.formData.product.secondaryButton
          );
          state.formData.product.explanation = mapResponseToState(
            data["productScreenExplaination"],
            state.formData.product.explanation
          );
          state.formData.product.loading = true;
          state.formData.product.display =
            data?.productScreenVisibility?.data?.isDisplay == 0 ? 0 : 1;
          state.formData.product.criteria =
            data?.productScreenVisibility?.data?.criteria || "MSI";
          break;
        case 9:
          state.formData.end.content = mapResponseToState(
            data["downloadAppContent"],
            state.formData.end.content
          );

          state.formData.end.image.blob =
            data["downloadAppContentQRImage"]["data"]["qrCodeBlob"];

          if (data["downloadAppContentQRImage"]["data"]["qrCodeBlob"]) {
            const qrCode = yield downloadAssets(
              data["downloadAppContentQRImage"]["data"]["qrCodeBlob"]
            );
            state.formData.end.image.src = qrCode
              ? URL.createObjectURL(qrCode)
              : "";
          } else {
            state.formData.end.image.src = "";
          }

          state.formData.end.image.blob =
            data["downloadAppContentQRImage"]["data"]["qrCodeBlob"];

          state.formData.end.button = mapResponseToState(
            data["nextUserButton"],
            state.formData.end.button
          );

          state.formData.end.loading = true;
          break;
        default:
      }
      yield put(
        actions.setForm({
          ...state.formData,
          tempForm: state.formData[formListScreens[componentId]],
          formActive: componentId,
          activeProgram: programmeId,
        })
      );
      yield put(
        actions.setFilter({
          ...state.filterData,
          loading: false,
          screenLoading: false,
        })
      );
    } else {
      const state = yield select();
      yield put(
        actions.setFilter({
          ...state.filterData,
          apiError: true,
        })
      );
    }
  } catch (error) {
    console.log(error);
    const state = yield select();
    yield put(
      actions.setFilter({
        ...state.filterData,
        apiError: true,
      })
    );
  }
}
