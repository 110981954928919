import React from 'react';
import LoginPage from '../modules/Login';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import Aspire2bAdmin from './App';
import { LoginCallback, Security } from '@okta/okta-react';
import OktaAuth, { toRelativeUrl } from '@okta/okta-auth-js';
import { OKTACONFIG } from '../data/const/variables';

const oktaAuth = new OktaAuth(OKTACONFIG);

const Auth: React.FC = () => {

  const navigate = useNavigate();
  const restoreOriginalUri = async (_oktaAuth:any, originalUri:any) => {
    if(!await oktaAuth.isAuthenticated()){
        navigate('/auth');
    }
    else{
        navigate('/');
    }
  };


  return (
    <div className="aspire2b-admin">
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} >
            <Routes>
                <Route path='/' element={<Aspire2bAdmin/>}/>
                <Route path='/auth' element={<LoginPage/>}/>
                <Route path='/login' element={<LoginCallback/>}/>
                <Route path='/logout' element={<LoginPage/>}/>
            </Routes>
        </Security>
    </div>
  );
}

export default Auth;
