import axios from "axios";
import { API_URL } from "../const/variables";
import { generateToken, getHeaders } from "./auth";

// Call API to get programmes

export const fetchRegion = async () => {
  return axios.get(`${API_URL}/region`, { headers: await getHeaders() });
};

export const fetchLocale = async () => {
  return axios.get(`${API_URL}/locale`, { headers: await getHeaders() });
};

export const filterProgrammes = async (
  regionCode: string,
  localeCode: string,
  createdAt: string,
  createdBy: string
) => {
  let URL = `${API_URL}/programme?state=in(DRAFT,PUBLISHED)`;
  if (regionCode) {
    URL = URL.concat(`&regionCode=eq(${regionCode})`);
  }
  if (localeCode) {
    URL = URL.concat(`&localeCode=eq(${localeCode})`);
  }
  if (createdAt) {
    URL = URL.concat(`&createdAt=gte(${createdAt})`);
  }
  if (createdBy) {
    URL = URL.concat(`&createdBy=eq(${createdBy})`);
  }
  return axios.get(URL, { headers: await getHeaders() });
};

export const createProgramme = async (
  programmeName: string,
  programmeDescription: string,
  regionName: string,
  localeName: string
) => {
  return axios.post(
    `${API_URL}/programme`,
    {
      programmeName,
      programmeDescription,
      regionName,
      localeName,
    },
    {
      headers: await getHeaders(),
    }
  );
};

export const fetchProgrammeConfig = async (
  programmeId: string,
  componentId: string
) => {
  return axios.get(
    `${API_URL}/programme/configuration?programmeId=${programmeId}&componentId=${componentId}`,
    { headers: await getHeaders() }
  );
};

export const updateProgramme = async (
  programmeId: string,
  programmeName: string,
  programmeDescription: string,
  regionName: string,
  localeName: string
) => {
  return axios.put(
    `${API_URL}/programme/${programmeId}`,
    { programmeName, programmeDescription, regionName, localeName },
    { headers: await getHeaders() }
  );
};

export const updateProgrammeState = async (
  programmeId: string,
  state: string
) => {
  return axios.post(
    `${API_URL}/programme/state`,
    { programmeId, state },
    { headers: await getHeaders() }
  );
};

export const deleteProgramme = async (programmeId: string) => {
  return axios.delete(`${API_URL}/programme/${programmeId}`, {
    headers: await getHeaders(),
  });
};

export const cloneProgramme = async (
  programmeId: string,
  programmeName: string,
  regionName: string,
  localeName: string
) => {
  return axios.post(
    `${API_URL}/programme/clone`,
    {
      programmeId,
      programmeName,
      regionName,
      localeName,
      programmeDescripion: programmeName,
    },
    { headers: await getHeaders() }
  );
};

export const uploadAssets = async (
  file: File,
  programmeId: string,
  componentId: string
) => {
  const formData = new FormData();
  formData.append("file", file);
  return axios.post(
    `${API_URL}/assets/upload?programmeId=${programmeId}&componentId=${componentId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${await generateToken()}`,
      },
    }
  );
};

export const downloadAssets = async (blobName: string) => {
  if (blobName.toString().startsWith("[")) {
    return "";
  }
  var url = await fetch(`${API_URL}/assets/download?blobName=${blobName}`, {
    method: "GET",
    headers: await getHeaders(),
  });

  // axios.get(`${API_URL}/assets/download?blobName=${blobName}`, {
  //   headers: await getHeaders(),
  //   responseType: "blob",
  // });
  return await url?.blob();
};

export const upsertProgrammeConfig = async (
  programmeId: string,
  configuration: any
) => {
  return axios.put(
    `${API_URL}/programme/${programmeId}/configuration`,
    { configuration: configuration },
    { headers: await getHeaders() }
  );
};
