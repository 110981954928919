import React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import pepsico from "../data/assets/images/logoloading.png";

const Loading: React.FC = () => {

  return (
    <Box sx={{
      display: 'flex', 
      justifyContent: "center",
      alignItems: "center",
      position: "fixed", 
      width: "100%", 
      height: "100%", 
      background: "#ffffff",
      zIndex: 999,
      opacity: '0.9',
    }}>
      
      <Box sx={{ width: '50px' }}>
        <img src={pepsico} style={{maxWidth: "50px", marginBottom: "4px"}}  alt="" />
        <LinearProgress sx={{ height: '2px', color: "#f1d1d7" }} />
      </Box>
    </Box>
  );
}

export default Loading;