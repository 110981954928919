import React, { ChangeEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import AccordionForms from "./components/AccordionForms";
import Typography from "@mui/material/Typography";
import { TextType } from "../../data/const/dataType";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Editor from "./components/Editor";
import { actions } from "../../state";

interface FormType {
  titleForm: string;
  question1: TextType;
  error10: TextType;
  question2: TextType;
  error20: TextType;
  error21: TextType;
  question3: TextType;
  error30: TextType;
  error31: TextType;
  question4: TextType;
  error40: TextType;
  button: TextType;
  display: number;
}

interface FormData {
  form: FormType;
}

type StateType = {
  formData: FormData;
};

const Form: React.FC = () => {
  const formData = useSelector((state: StateType) => state.formData);

  const dispatch = useDispatch();

  const handleDisplayForm = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      actions.setForm({
        ...formData,
        startForm: true,
        form: {
          ...formData.form,
          display: parseInt(event.target.value),
        },
      })
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "left",
          marginTop: "64px",
          background: "#EFF5FC",
          padding: "16px",
          color: "#3A3A3A",
          fontWeight: "700",
          fontSize: "18px",
          flexDirection: "column",
        }}
      >
        {formData.form.titleForm}
        <Box>
          <RadioGroup
            row
            value={formData.form.display}
            onChange={handleDisplayForm}
          >
            <FormControlLabel value={1} control={<Radio />} label="Display" />
            <FormControlLabel value={0} control={<Radio />} label="Hide" />
          </RadioGroup>
          <Typography variant="caption">
            This page is optional and you can choose to not display
          </Typography>
        </Box>
      </Box>

      <Box className="scroll-form-accordion">
        <AccordionForms title="Add your list of questions">
          <Typography className="subtitle-accordion-form">
            Question 1
          </Typography>
          <Typography className="subtitle-accordion-form">
            Enter your question
          </Typography>
          <Editor form="form" field="question1" rows={1} format="hide" />
          <Typography className="subtitle-accordion-form">
            Enter valid Metrics
          </Typography>
          <Editor form="form" field="label10" rows={1} format="hide" />
          <Typography className="subtitle-accordion-form">
            Enter your error message
          </Typography>
          <Editor form="form" field="error10" rows={2} format="hide" />

          <Typography className="subtitle-accordion-form">
            Question 2
          </Typography>
          <Typography className="subtitle-accordion-form">
            Enter your question
          </Typography>
          <Editor form="form" field="question2" rows={1} format="hide" />
          <Typography className="subtitle-accordion-form">
            Enter valid Metrics
          </Typography>
          <Box display="flex">
            <Editor form="form" field="label20" rows={1} format="hide" />
            <Box style={{ width: "8px", height: "8px" }}></Box>
            <Editor form="form" field="label21" rows={1} format="hide" />
          </Box>
          <Typography className="subtitle-accordion-form">
            Enter your error messages
          </Typography>
          <Editor form="form" field="error20" rows={2} format="hide" />
          <Box style={{ width: "8px", height: "8px" }}></Box>
          <Editor form="form" field="error21" rows={2} format="hide" />

          <Typography className="subtitle-accordion-form">
            Question 3
          </Typography>
          <Typography className="subtitle-accordion-form">
            Enter your question
          </Typography>
          <Editor form="form" field="question3" rows={1} format="hide" />
          <Typography className="subtitle-accordion-form">
            Enter valid Metrics
          </Typography>
          <Box display="flex">
            <Editor form="form" field="label30" rows={1} format="hide" />
            <Box style={{ width: "8px", height: "8px" }}></Box>
            <Editor form="form" field="label31" rows={1} format="hide" />
          </Box>
          <Typography className="subtitle-accordion-form">
            Enter your error messages
          </Typography>
          <Editor form="form" field="error30" rows={2} format="hide" />
          <Box style={{ width: "8px", height: "8px" }}></Box>
          <Editor form="form" field="error31" rows={2} format="hide" />
          <Box style={{ width: "8px", height: "8px" }}></Box>
          <Editor form="form" field="error40" rows={2} format="hide" />
          <Typography className="subtitle-accordion-form">
            Question 4
          </Typography>
          <Typography className="subtitle-accordion-form">
            Enter your question
          </Typography>
          <Editor form="form" field="question4" rows={1} format="hide" />
          <Typography className="subtitle-accordion-form">
            Enter valid Metrics
          </Typography>
          <Box display="flex">
            <Editor form="form" field="label40" rows={1} format="hide" />
            <Box style={{ width: "8px", height: "8px" }}></Box>
            <Editor form="form" field="label41" rows={1} format="hide" />
          </Box>
          <Typography className="subtitle-accordion-form">
            Question 5
          </Typography>
          <Typography className="subtitle-accordion-form">
            Enter your question
          </Typography>
          <Editor form="form" field="question5" rows={1} format="hide" />
          <Typography className="subtitle-accordion-form">
            Enter valid Metrics
          </Typography>
          <Box display="flex">
            <Editor form="form" field="label42" rows={1} format="hide" />
            <Box style={{ width: "8px", height: "8px" }}></Box>
            <Editor form="form" field="label43" rows={1} format="hide" />
          </Box>
        </AccordionForms>

        <AccordionForms title="Customize your button">
          <Typography className="subtitle-accordion-form">
            Button Text
          </Typography>
          <Editor form="form" field="button" rows={1} format="button" />
        </AccordionForms>
      </Box>
    </Box>
  );
};

export default Form;
