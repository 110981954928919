import { put, select } from "redux-saga/effects";
import { uploadAssets, downloadAssets } from "../../data/api/endpoints";
import { actions } from "..";
import { apiListScreens } from "../../data/const/validateData";

export default function* getUploadAssetsSaga(param) {
  const state = yield select();
  try {
    const { file, programmeId, componentId } = param.payload;

    const resp = yield uploadAssets(
      file,
      programmeId,
      apiListScreens[componentId]
    );

    if (!resp.status || resp.status === 200) {
      const image = yield downloadAssets(resp["data"]["data"]);
      switch (componentId) {
        case 1:
          state.formData.brand.logo.blob = resp["data"]["data"];
          state.formData.brand.logo.src = image
            ? URL.createObjectURL(image)
            : "";
          break;

        case 2:
          state.formData.begin.video.blob = resp["data"]["data"];
          state.formData.begin.video.src = image
            ? URL.createObjectURL(image)
            : "";
          break;

        case 3:
          state.formData.howScanWork.image.blob = resp["data"]["data"];
          state.formData.howScanWork.image.src = image
            ? URL.createObjectURL(image)
            : "";
          break;

        case 4:
          state.formData.termsConditions.image.blob = resp["data"]["data"];
          state.formData.termsConditions.image.src = image
            ? URL.createObjectURL(image)
            : "";
          break;

        case 8:
          break;
        case 9:
          state.formData.end.image.blob = resp["data"]["data"];
          state.formData.end.image.src = image
            ? URL.createObjectURL(image)
            : "";
          break;
        default:
      }

      yield put(actions.setForm(state.formData));
    } else {
      yield put(actions.setFilter(state.filterData));
    }
  } catch (error) {
    yield put(actions.setFilter(state.filterData));
  }
}
