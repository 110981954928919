import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Upload from "./components/Upload";
import Editor from "./components/Editor";
import TitleForm from "./components/TitleForm";
import { TextType, UploadType } from "../../data/const/dataType";
import AccordionForms from "./components/AccordionForms";
import { actions } from "../../state";
import Typography from "@mui/material/Typography";

interface EndType {
  titleForm: string;
  content: TextType;
  image: UploadType;
}

interface FormData {
  end: EndType;
  formActive: number;
}

type StateType = {
  formData: FormData;
  filterData: any;
};

const End: React.FC = () => {
  const formData = useSelector((state: StateType) => state.formData);
  const filterData = useSelector((state: StateType) => state.filterData);

  const dispatch = useDispatch();

  const handleSave = (value: string, file: any) => {
    dispatch(
      actions.setForm({
        ...formData,
        startForm: true,
        end: {
          ...formData.end,
          image: {
            ...formData.end.image,
            src: value,
            upload: file,
          },
        },
      })
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <TitleForm title={formData.end.titleForm} />
      <Box className="scroll-form-accordion">
        <AccordionForms title="Add your text">
          <Typography className="subtitle-accordion">Explanation</Typography>
          <Editor form="end" field="content" rows={4} />
        </AccordionForms>
        <AccordionForms title="Add your generated QR code or Image">
          <Typography className="subtitle-accordion">
            Upload your QR code or Image
          </Typography>
          <Upload type={"image"} handleSave={handleSave} />
        </AccordionForms>
        <AccordionForms title="Customize button">
          <Typography className="subtitle-accordion">Button Text</Typography>
          <Editor form="end" field="button" rows={1} format="button" />
        </AccordionForms>
      </Box>
    </Box>
  );
};

export default End;
