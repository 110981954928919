import React, { useState } from "react";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-material-ui-carousel";
import { TextType, ResultWidget } from "../../data/const/dataType";
import { editorStyles, onScreenClick } from "../../data/utils/functions";
import Logo from "./components/Logo";
import Close from "./components/Close";
import WidgetResult from "./components/WidgetResult";
import { Button, List, ListItem, ListItemText } from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { actions } from "../../state";

interface ResultsType {
  titleForm: string;
  activeForm: number;
  header: TextType;
  footer: TextType;
  listWidgets: ResultWidget[];
  selectedWidget: ResultWidget[];
  tabDisplay: number;
  activeMSIIndex: number;
  nextButton: TextType;
  showDownloadButton: boolean;
}

interface FormData {
  results: ResultsType;
  product: any;
  brand: any;
}

type StateType = {
  formData: FormData;
};

const Results: React.FC = () => {
  const formData = useSelector((state: StateType) => state.formData);

  const dispatch = useDispatch();

  const handleStressIndex = (index: number) => {
    dispatch(
      actions.setForm({
        ...formData,
        results: {
          ...formData.results,
          activeMSIIndex: index,
        },
      })
    );
  };

  const renderExplaination = (value: string, isList?: boolean) => {
    if (isList) {
      let list = value?.split("\n");
      return (
        <Box sx={{ textAlign: "left" }}>
          {list?.map((text) => (
            <Box
              sx={{
                marginTop: "10px",
              }}
            >{`\u2022 ${text}`}</Box>
          ))}
        </Box>
      );
    }
    return value;
  };

  const handleExplainScreen = () => {
    dispatch(
      actions.setForm({
        ...formData,
        results: {
          ...formData.results,
          tabDisplay: 0,
        },
      })
    );
  };

  return (
    <Box
      width={"100%"}
      sx={{
        overflow: "scroll",
        paddingTop: "20px",
        paddingBottom: "20px",
      }}
    >
      <Close />
      <Logo />
      {formData.results.tabDisplay === 1 && (
        <>
          {formData.results.selectedWidget.map((item, i) => {
            if (i === formData.results.activeForm) {
              return (
                <Box
                  key={i}
                  flex={1}
                  sx={{
                    margin: "24px 40px",
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        // borderWidth: 1,
                        // borderStyle: "solid",
                        borderRadius: 2,
                        padding: 2,
                        background: item?.background, //236,49,35
                      }}
                    >
                      <Box
                        sx={{
                          overflow: "scroll",
                          maxHeight: "450px",
                        }}
                      >
                        <Box
                          sx={{
                            ...editorStyles(item?.titleWidget),
                            borderWidth: 0,
                            background: "none",
                            borderStyle: "solid",
                            borderRadius: 2,
                            textAlign: "center",
                            marginBottom: "8px",
                            padding: 0,
                            whiteSpace: "pre-line",
                          }}
                        >
                          {item?.titleWidget?.value}
                        </Box>
                        {item.code === "MSI" && (
                          <Box>
                            <Box
                              sx={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "16px",
                                display: "flex",
                              }}
                            >
                              {item.type == "graph"
                                ? item?.indexes?.map((box, index) => (
                                    <Box
                                      sx={{
                                        width: 64,
                                        height: 40,
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "row-reverse",
                                        borderRadius: "6px",
                                        backgroundColor: box.backgroundColor,
                                        display: "flex",
                                      }}
                                    >
                                      <Button
                                        onClick={() => handleStressIndex(index)}
                                        sx={{
                                          width: "100%",
                                          fontSize: 18,
                                          color: index < 2 ? "black" : "white",
                                          whiteSpace: "pre-line",
                                        }}
                                      >
                                        {box.number}
                                      </Button>
                                    </Box>
                                  ))
                                : [item.indexes[0], item.indexes[4]]?.map(
                                    (box, index) => (
                                      <Box
                                        sx={{
                                          width: "45%",
                                          height: 40,
                                          justifyContent: "center",
                                          alignItems: "center",
                                          flexDirection: "row-reverse",
                                          borderRadius: "6px",
                                          backgroundColor: box.backgroundColor,
                                          display: "flex",
                                        }}
                                      >
                                        <Button
                                          onClick={() =>
                                            handleStressIndex(
                                              index == 0 ? index : 4
                                            )
                                          }
                                          sx={{
                                            width: "100%",
                                            fontSize: 18,
                                            color:
                                              index == 0 ? "black" : "white",
                                            whiteSpace: "pre-line",
                                          }}
                                        >
                                          {index == 0 ? "Low" : "High"}
                                        </Button>
                                      </Box>
                                    )
                                  )}
                            </Box>
                            <Box
                              sx={{
                                ...editorStyles(item.explanation),
                                fontSize: "18px",
                                fontWeight: "bold",
                                marginBottom: "20px",
                                background: "transparent",
                                whiteSpace: "pre-line",
                              }}
                            >
                              {`State: ${
                                item?.indexes[formData?.results?.activeMSIIndex]
                                  ?.state || ""
                              }`}
                            </Box>
                          </Box>
                        )}
                        <Box
                          sx={{
                            ...editorStyles(item.explanation),
                            background: "transparent",
                            whiteSpace: "pre-line",
                          }}
                        >
                          {renderExplaination(
                            item.code == "MSI"
                              ? item?.indexes[formData?.results?.activeMSIIndex]
                                  ?.explaination || ""
                              : item.explanation.value,
                            item?.explanation?.isList
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      margin: "24px 40px",
                      textAlign: "center",
                    }}
                  >
                    <Box
                      onClick={() => handleExplainScreen()}
                      sx={{
                        ...editorStyles(item.explanationButton),
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderRadius: 2,
                        padding: "8px 32px",
                        cursor: "pointer",
                        display: "inline-block",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {item.explanationButton.value}
                    </Box>
                  </Box>
                </Box>
              );
            }
          })}
        </>
      )}
      {formData.results.tabDisplay === 0 && (
        <>
          <Box
            sx={{
              ...editorStyles(formData.results.header),
              borderWidth: 0,
              borderStyle: "solid",
              borderRadius: 2,
              padding: 0,
              textAlign: "center",
              background: "none",
              whiteSpace: "pre-line",
            }}
          >
            {formData.results.header.value}
          </Box>
          <Box
            sx={{
              padding: "24px",
            }}
          >
            <Carousel
              animation="slide"
              autoPlay={false}
              swipe={false}
              indicators={true}
              navButtonsAlwaysInvisible={true}
              index={formData.results.activeForm}
            >
              {formData.results.selectedWidget.map((item, i) => {
                return <WidgetResult key={i} item={item} />;
              })}
            </Carousel>
          </Box>
          {!formData?.results?.showDownloadButton && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <Box
                onClick={() =>
                  onScreenClick(formData?.product?.display ? 8 : 9)
                }
                sx={{
                  ...editorStyles(formData.results.nextButton),
                  padding: "4px 20px",
                  borderRadius: 2,
                  borderWidth: 1,
                  borderStyle: "solid",
                  whiteSpace: "pre-line",
                  display: "inline",
                }}
              >
                {formData.results.nextButton.value}
              </Box>
            </Box>
          )}
          <Box
            sx={{
              ...editorStyles(formData.results.footer),
              background: "none",
              borderWidth: 0,
              borderStyle: "solid",
              borderRadius: 4,
              padding: "8px 32px",
              opacity: 0.8,
              whiteSpace: "pre-line",
            }}
          >
            {formData.results.footer.value}
          </Box>
        </>
      )}
    </Box>
  );
};

export default Results;
