import React from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";

interface Logo {
  src: string;
  blob: string;
}

interface Brand {
  logo: Logo;
  isLandscape?: boolean;
}

interface FormData {
  formActive: number;
  brand: Brand;
}

type StateType = {
  formData: FormData;
};

const Brand: React.FC = () => {
  const formData = useSelector((state: StateType) => state.formData);

  return (
    <>
      {formData.formActive === 1 && (
        <img
          src={formData.brand.logo.src}
          style={
            !formData.brand.isLandscape
              ? { width: "60px", height: "60px", objectFit: "contain" }
              : { width: "120px", height: "60px", objectFit: "contain" }
          }
          alt=""
        />
      )}
      {formData.formActive === 6 && (
        <Box
          sx={{
            padding: "24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#ffffff",
            borderRadius: "16px",
            background: "rgba(0,0,0,0.1)", //236,49,35
          }}
        >
          <InfoIcon sx={{ marginRight: "8px", color: "#ffffff" }} /> No
          customization available
        </Box>
      )}
    </>
  );
};

export default Brand;
