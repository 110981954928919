import React from "react";

interface FaceProps extends React.SVGProps<SVGSVGElement> {
  // Additional props
}

export const Face: React.FC<FaceProps> = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="60.000000pt"
      height="60.000000pt"
      viewBox="0 0 900.000000 900.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,900.000000) scale(0.100000,-0.100000)"
        fill="#808080"
        stroke="none"
      >
        <path
          d="M4790 8826 c0 -7 7 -19 15 -26 8 -7 15 -16 15 -21 0 -4 20 -50 44
          -101 23 -51 49 -109 56 -128 7 -19 17 -39 22 -44 4 -6 8 -20 8 -32 0 -13 4
          -25 9 -28 5 -3 11 -20 14 -38 3 -18 13 -69 23 -113 20 -91 25 -382 7 -470
          -41 -204 -124 -419 -220 -573 -18 -29 -33 -54 -33 -56 0 -6 -157 -234 -183
          -267 -21 -26 -39 -49 -102 -129 -16 -21 -70 -85 -120 -141 -49 -55 -112 -127
          -140 -159 -27 -31 -87 -99 -133 -151 -118 -133 -122 -137 -183 -214 -66 -84
          -219 -299 -219 -309 0 -4 -11 -22 -24 -39 -62 -81 -265 -478 -266 -519 0 -7
          -7 -22 -15 -32 -8 -11 -15 -30 -15 -42 0 -13 -4 -26 -10 -29 -5 -3 -10 -17
          -10 -30 0 -13 -4 -26 -9 -29 -5 -3 -11 -20 -14 -38 -3 -18 -10 -51 -16 -73
          -47 -182 -54 -238 -55 -470 0 -152 4 -252 12 -295 31 -155 45 -216 53 -225 5
          -5 9 -17 9 -27 0 -23 38 -141 56 -177 8 -14 14 -29 14 -33 0 -17 92 -209 153
          -318 11 -19 44 -73 75 -120 31 -47 61 -92 67 -101 26 -42 101 -144 210 -289
          61 -81 144 -195 198 -272 32 -46 94 -134 138 -196 43 -62 79 -114 79 -117 0
          -6 92 -149 100 -155 13 -10 190 -371 190 -388 0 -10 4 -22 8 -28 5 -5 14 -27
          20 -49 6 -22 19 -65 28 -95 14 -43 17 -92 17 -225 0 -147 -3 -181 -22 -250
          -13 -44 -25 -89 -28 -100 -3 -11 -14 -39 -24 -62 -10 -24 -19 -47 -19 -52 0
          -11 -70 -147 -124 -239 -20 -35 -36 -68 -36 -73 0 -5 -3 -11 -7 -13 -10 -4
          -63 -74 -63 -82 0 -16 37 -2 90 34 32 22 61 40 65 40 3 0 -7 -18 -21 -40 -78
          -117 59 -16 309 229 344 336 527 628 592 939 17 81 20 284 6 341 -5 20 -15
          61 -22 91 -47 214 -158 420 -346 641 -40 47 -73 91 -73 97 0 14 77 50 143 68
          32 8 104 14 188 14 180 0 326 -22 619 -95 202 -49 309 -61 417 -45 143 21
          241 73 287 153 51 88 53 181 7 342 -25 85 -26 135 -5 206 21 73 51 123 134
          220 38 46 77 102 86 125 15 40 15 46 -6 108 -12 37 -39 89 -61 116 -21 27
          -39 52 -39 56 0 8 0 7 104 87 42 32 88 71 101 85 25 27 33 76 15 87 -5 3
          -10 13 -10 22 0 30 -31 80 -95 152 -140 160 -124 226 85 338 104 56 125 74
          146 128 14 38 14 47 0 94 -17 59 -68 155 -100 191 -13 14 -101 126 -196 250
          -95 124 -183 236 -196 250 -12 14 -34 48 -48 76 -24 47 -26 63 -26 170 1 127
          -1 118 108 569 27 116 55 237 61 271 14 78 14 347 0 433 -25 155 -87 334
          -192 551 -49 103 -132 242 -199 337 -78 109 -245 284 -363 378 -80 64 -227
          165 -240 165 -2 0 -28 15 -57 33 -67 42 -234 122 -328 156 -19 8 -48 19 -65
          26 -16 7 -61 23 -100 35 -38 12 -78 26 -87 31 -27 13 -58 11 -58 -5z"
        />
        <path
          d="M4159 8559 c-9 -10 -17 -26 -17 -34 0 -7 -4 -17 -10 -20 -5 -3 -10
          -15 -10 -25 0 -32 -137 -282 -170 -310 -3 -3 -19 -23 -35 -45 -47 -64 -153
          -172 -245 -248 -47 -39 -87 -74 -90 -77 -3 -3 -35 -25 -71 -48 -37 -24 -75
          -52 -84 -62 -10 -11 -22 -20 -28 -20 -5 0 -45 -25 -89 -55 -44 -30 -81 -55
          -84 -55 -4 0 -67 -40 -219 -138 -184 -120 -319 -230 -435 -357 -33 -36 -140
          -190 -140 -202 0 -5 -13 -35 -29 -68 -27 -55 -40 -98 -70 -228 -14 -60 -14
          -401 0 -481 5 -34 24 -119 40 -191 17 -71 33 -145 36 -163 3 -18 9 -35 14 -38
          5 -3 9 -16 9 -29 0 -13 5 -27 10 -30 6 -3 10 -19 10 -35 0 -16 4 -31 9 -34 5
          -3 11 -16 14 -28 8 -33 71 -208 88 -243 9 -18 22 -30 34 -30 19 0 20 6 18 90
          -1 50 -5 221 -9 380 -7 321 1 443 43 635 75 345 225 556 573 805 85 61 201
          145 259 189 267 198 471 452 585 729 38 90 48 138 22 105 -7 -10 -16 -18 -20
          -18 -10 0 -7 38 5 45 6 4 8 14 5 22 -3 8 0 23 8 36 21 39 56 146 56 169 0 11
          7 26 15 33 9 7 13 20 10 28 -3 8 2 23 10 32 9 10 12 20 7 23 -4 4 -16 -3 -25
          -14z m-107 -405 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9 14 15 11 15 -7z"
        />
      </g>
    </svg>
  );
};
