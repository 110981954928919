import { takeEvery } from 'redux-saga/effects';
import getRegionsSaga from './sagas/getRegions';
import getLocalesSaga from './sagas/getLocales';
import getFilterProgrammesSaga from './sagas/getFilterProgrammes';
import getCreateProgrammeSaga from './sagas/getCreateProgramme';
import getUpdateProgrammeSaga from './sagas/getUpdateProgramme';
import getUpdateProgrammeStateSaga from './sagas/getUpdateProgrammeState';
import getDeleteProgrammeSaga from './sagas/getDeleteProgramme';
import getFetchProgramConfigSaga from './sagas/getProgrammeConfig';
import getCloneProgrammeSaga from './sagas/getCloneProgramme';
import getUploadAssetsSaga from './sagas/getUploadAssets';
import getUpsertProgrammeConfigurationSaga from './sagas/getUpsertProgrammeConfig';

export const commonSaga = [
  takeEvery('GET_REGIONS', getRegionsSaga),
  takeEvery('GET_LOCALES', getLocalesSaga),
  takeEvery('GET_FILTER_PROGRAMMES', getFilterProgrammesSaga),
  takeEvery('GET_CREATE_PROGRAMME', getCreateProgrammeSaga),
  takeEvery('GET_UPDATE_PROGRAMME', getUpdateProgrammeSaga),
  takeEvery('GET_UPDATE_PROGRAMME_STATE', getUpdateProgrammeStateSaga),
  takeEvery('GET_DELETE_PROGRAMME', getDeleteProgrammeSaga),
  takeEvery('GET_FETCH_PROGRAM_CONFIG', getFetchProgramConfigSaga),
  takeEvery('GET_CLONE_PROGRAMME', getCloneProgrammeSaga),
  takeEvery('GET_UPLOAD_ASSETS', getUploadAssetsSaga),
  takeEvery('GET_UPSERT_PROGRAMME_CONFIGURATION', getUpsertProgrammeConfigurationSaga)
];
