import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Forms from "../modules/Forms";
import Dialog from "./Dialog";
import { formListScreens } from "../data/const/validateData";
import { actions } from "../state";

interface FilterData {
  loadingForm: boolean;
}

interface FormData {
  formActive: number;
  preview: boolean;
  loading: boolean;
  startForm: boolean;
  tempForm: any;
  activeProgram: boolean;
}

type StateType = {
  filterData: FilterData;
  formData: FormData;
};
const Form: React.FC = () => {
  const formData = useSelector((state: StateType) => state.formData);
  const filterData = useSelector((state: StateType) => state.filterData);

  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(
      actions.setFilter({
        ...filterData,
        dialogDisplay: true,
        dialogQuestion: "Do you want to cancel your chnages?",
        dialogData: [0, "No", "Yes"],
      })
    );
  };

  const handleCancelConfimed = () => {
    dispatch(
      actions.setForm({
        ...formData,
        [formListScreens[formData.formActive]]: formData.tempForm,
        startForm: false,
      })
    );
    dispatch(
      actions.setFilter({
        ...filterData,
        dialogDisplay: false,
        dialogQuestion: "",
        dialogData: [0, "", ""],
      })
    );
  };

  const handleBack = () => {
    if (!formData.startForm) {
      dispatch(
        actions.setForm({
          ...formData,
          formActive: formData.formActive - 1,
        })
      );
      dispatch(
        actions.setFilter({
          ...filterData,
          loading: true,
        })
      );
      dispatch(
        actions.getProgrammeConfig({
          programmeId: formData.activeProgram,
          componentId: formData.formActive - 1,
        })
      );
    } else {
      dispatch(
        actions.setFilter({
          ...filterData,
          loading: true,
        })
      );
      dispatch(actions.getUpsertProgrammeConfig({ type: 1 }));
    }
  };

  const handleContinue = () => {
    if (!formData.startForm && formData.formActive < 9) {
      dispatch(
        actions.setForm({
          ...formData,
          formActive: formData.formActive + 1,
        })
      );
      dispatch(
        actions.setFilter({
          ...filterData,
          screenLoading: true,
        })
      );
      dispatch(
        actions.getProgrammeConfig({
          programmeId: formData.activeProgram,
          componentId: formData.formActive + 1,
        })
      );
    } else {
      dispatch(
        actions.setFilter({
          ...filterData,
          screenLoading: true,
        })
      );
      dispatch(
        actions.getUpsertProgrammeConfig({
          type: formData.formActive < 9 ? 2 : 0,
        })
      );
    }
  };

  return (
    <>
      {formData.formActive < 10 && !formData.preview && (
        <Box
          sx={{
            position: "fixed",
            width: "400px",
            height: "100vh",
            zIndex: "10",
            background: "#ffffff",
            right: 0,
            boxShadow: "-10px 0px 15px rgba(173,216,230,0.5)",
          }}
        >
          <Forms />
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              display: "flex",
              justifyContent: "end",
              padding: "16px",
              width: "100%",
              background: "#FBFCFE",
              borderTop: "1px solid #dddddd",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button
                variant="outlined"
                disabled={!formData.startForm}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Box>
                <Button
                  variant="contained"
                  onClick={handleBack}
                  disabled={formData.formActive === 1}
                  style={{ marginLeft: "16px" }}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  onClick={handleContinue}
                  disabled={formData.formActive == 9 && !formData.startForm}
                  style={{
                    marginLeft: "16px",
                  }}
                >
                  {formData.formActive < 9 ? "Continue" : "Save Draft"}
                </Button>
              </Box>
            </Box>
          </Box>
          {formData.startForm && (
            <Dialog handleConfirm={handleCancelConfimed} />
          )}
        </Box>
      )}
    </>
  );
};

export default Form;
