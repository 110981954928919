import { put, select } from "redux-saga/effects";
import { actions } from "..";
import { fetchRegion } from "../../data/api/endpoints";
import { getSafe } from "../../data/utils/functions";

export default function* getRegionsSaga() {
  try {
    const resp = yield fetchRegion();
    if (!resp.status || resp.status === 200) {
      const state = yield select();
      const listRegions = getSafe(() => resp["data"]["data"]) || [];
      yield put(
        actions.setFilter({
          ...state.filterData,
          regions: listRegions.map((region, index) => {
            return {
              id: region["regionCode"],
              title: region["regionName"],
            };
          }),
        })
      );
    } else {
      const state = yield select();
      yield put(
        actions.setFilter({
          ...state.filterData,
          apiError: true,
        })
      );
    }
  } catch (error) {
    const state = yield select();
    yield put(
      actions.setFilter({
        ...state.filterData,
        apiError: true,
      })
    );
  }
}
