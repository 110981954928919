import React, { ChangeEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import TitleForm from "./components/TitleForm";
import ColorPicker from "./components/ColorPicker";
import AccordionForms from "./components/AccordionForms";
import Typography from "@mui/material/Typography";
import Upload from "./components/Upload";
import { UploadType, ColorType, ProgramType } from "../../data/const/dataType";
import { actions } from "../../state";
import { Switch } from "@mui/material";

interface Brand {
  titleForm: string;
  logo: any;
  colorBaground: ColorType;
  closeColor: ColorType;
  closeBackground: ColorType;
  isLandscape?: boolean;
}

interface FormData {
  brand: Brand;
  formActive: number;
}

type StateType = {
  formData: FormData;
  filterData: any;
};

const Brand: React.FC = () => {
  const formData = useSelector((state: StateType) => state.formData);
  const filterData = useSelector((state: StateType) => state.filterData);

  const dispatch = useDispatch();

  const handleLogoSave = (value: string, file: any) => {
    dispatch(
      actions.setForm({
        ...formData,
        startForm: true,
        brand: {
          ...formData.brand,
          logo: {
            ...formData.brand.logo,
            src: value,
            upload: file,
          },
        },
      })
    );
  };

  const handleColorBaground = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      actions.setForm({
        ...formData,
        startForm: true,
        brand: {
          ...formData.brand,
          colorBaground: {
            ...formData.brand.colorBaground,
            code: event.target.value,
          },
        },
      })
    );
  };

  const handleCloseBackground = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      actions.setForm({
        ...formData,
        startForm: true,
        brand: {
          ...formData.brand,
          closeBackground: {
            ...formData.brand.closeBackground,
            code: event.target.value,
          },
        },
      })
    );
  };

  const handleCloseColor = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      actions.setForm({
        ...formData,
        startForm: true,
        brand: {
          ...formData.brand,
          closeColor: {
            ...formData.brand.closeColor,
            code: event.target.value,
          },
        },
      })
    );
  };

  const toggleDirection = () => {
    dispatch(
      actions.setForm({
        ...formData,
        startForm: true,
        brand: {
          ...formData.brand,
          isLandscape: !formData.brand.isLandscape,
        },
      })
    );
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <TitleForm title={formData.brand.titleForm} />
      <Box className="scroll-form-accordion">
        <AccordionForms title="Add your logo">
          <Typography className="subtitle-accordion">
            Upload your logo using an external link
          </Typography>
          <Upload type="image" handleSave={handleLogoSave} />
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography className="subtitle-accordion">
              <span style={{ whiteSpace: "nowrap" }}>
                Direction:{" "}
                <Box
                  sx={{
                    fontWeight: "bold",
                    display: "inline", // Ensure it's inline
                  }}
                >
                  {formData.brand.isLandscape ? "Landscape" : "Portrait"}
                </Box>
              </span>
            </Typography>
            <Switch
              checked={formData.brand.isLandscape}
              onChange={toggleDirection}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Box>
        </AccordionForms>
        <AccordionForms title="Customize your background">
          <Typography className="subtitle-accordion">
            Choose a color for your background
          </Typography>
          <ColorPicker
            color={formData.brand.colorBaground.code}
            handleColorPicker={handleColorBaground}
          />
          <Typography className="subtitle-accordion">
            Note: The background color remains same for entire program
          </Typography>
        </AccordionForms>
        <AccordionForms title="Customize your cross button">
          <Typography className="subtitle-accordion">
            Choose a color for icon background
          </Typography>
          <ColorPicker
            color={formData.brand.closeBackground.code}
            handleColorPicker={handleCloseBackground}
          />
          <Typography className="subtitle-accordion">
            Choose a color for icon
          </Typography>
          <ColorPicker
            color={formData.brand.closeColor.code}
            handleColorPicker={handleCloseColor}
          />
          <Typography className="subtitle-accordion">
            Note: The icon customization remains same for entire program
          </Typography>
        </AccordionForms>
      </Box>
    </Box>
  );
};

export default Brand;
