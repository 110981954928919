import { formData } from "../data/const/formsData";
import { filterData } from "../data/const/filterData";
import _ from "lodash";

// Define the data type
interface Data {
  formData: typeof formData;
  filterData: typeof filterData;
}

// Define the action type
interface Action {
  type: string;
  payload: any;
}

const initialState: Data = {
  formData: _.cloneDeep(formData),
  filterData: _.cloneDeep(filterData),
};

export const SET_FORM = "SET_FORM";
export const SET_FILTER = "SET_FILTER";
export const SET_MENU = "SET_MENU";
export const GET_REGIONS = "GET_REGIONS";
export const GET_LOCALES = "GET_LOCALES";
export const GET_FILTER_PROGRAMMES = "GET_FILTER_PROGRAMMES";
export const GET_CREATE_PROGRAMME = "GET_CREATE_PROGRAMME";
export const GET_UPDATE_PROGRAMME = "GET_UPDATE_PROGRAMME";
export const GET_UPDATE_PROGRAMME_STATE = "GET_UPDATE_PROGRAMME_STATE";
export const GET_UPSERT_PROGRAMME_CONFIGURATION =
  "GET_UPSERT_PROGRAMME_CONFIGURATION";
export const GET_DELETE_PROGRAMME = "GET_DELETE_PROGRAMME";
export const GET_FETCH_PROGRAM_CONFIG = "GET_FETCH_PROGRAM_CONFIG";
export const GET_CLONE_PROGRAMME = "GET_CLONE_PROGRAMME";
export const GET_UPLOAD_ASSETS = "GET_UPLOAD_ASSETS";
export const GET_DOWNLOAD_ASSETS = "GET_UPLOAD_ASSETS";

export const actions = {
  setForm: (open?: any): Action => ({ type: SET_FORM, payload: open }),
  setFilter: (open?: any): Action => ({ type: SET_FILTER, payload: open }),
  setMenu: (open?: any): Action => ({ type: SET_MENU, payload: open }),
  getRegions: (open?: any): Action => ({ type: GET_REGIONS, payload: open }),
  getLocales: (open?: any): Action => ({ type: GET_LOCALES, payload: open }),
  getFilterProgrammes: (open?: any): Action => ({
    type: GET_FILTER_PROGRAMMES,
    payload: open,
  }),
  getCreateProgramme: (open?: any): Action => ({
    type: GET_CREATE_PROGRAMME,
    payload: open,
  }),
  getUpdateProgramme: (open?: any): Action => ({
    type: GET_UPDATE_PROGRAMME,
    payload: open,
  }),
  getUpdateProgrammeState: (open?: any): Action => ({
    type: GET_UPDATE_PROGRAMME_STATE,
    payload: open,
  }),
  getDeleteProgramme: (open?: any): Action => ({
    type: GET_DELETE_PROGRAMME,
    payload: open,
  }),
  getProgrammeConfig: (open?: any): Action => ({
    type: GET_FETCH_PROGRAM_CONFIG,
    payload: open,
  }),
  getCloneProgramme: (open?: any): Action => ({
    type: GET_CLONE_PROGRAMME,
    payload: open,
  }),
  getUpsertProgrammeConfig: (open?: any): Action => ({
    type: GET_UPSERT_PROGRAMME_CONFIGURATION,
    payload: open,
  }),
  getUploadAssets: (open?: any): Action => ({
    type: GET_UPLOAD_ASSETS,
    payload: open,
  }),
  getDownloadAssets: (open?: any): Action => ({
    type: GET_DOWNLOAD_ASSETS,
    payload: open,
  }),
};

const reducer = (state: Data = initialState, action: Action): Data => {
  const { type, payload } = action;
  switch (type) {
    case SET_FORM:
      return { ...state, formData: payload };
    case SET_FILTER:
      return { ...state, filterData: payload };
    default:
      return state;
  }
};

export default reducer;
