import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { actions } from "../state";
import { ProgramType } from "../data/const/dataType";

interface DialogProps {
  handleConfirm: any;
}

interface FilterData {
  loadingForm: boolean;
  validateForm: boolean;
  startForm: boolean;
  dialogDisplay: boolean;
  dialogQuestion: string;
  dialogData: string[];
  createProgram: ProgramType;
}

type StateType = {
  filterData: FilterData;
};

const Dialog: React.FC<DialogProps> = ({ handleConfirm }) => {
  const filterData = useSelector((state: StateType) => state.filterData);
  const dispatch = useDispatch();

  const closeDialog = () => {
    dispatch(
      actions.setFilter({
        ...filterData,
        dialogDisplay: false,
        dialogQuestion: "",
        dialogData: ["", "", ""],
      })
    );
  };

  const handleClose = () => {
    closeDialog();
  };

  return (
    <Modal
      open={filterData.dialogDisplay}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "8px",
          boxShadow: "0px 0px 24px rgba(0, 92, 188, 0.08)",
          background: filterData.dialogData[0] ? "#CCE7DD" : "#F6CCCC",
          pt: 2,
          px: 2,
          pb: 3,
          width: "400px",
        }}
      >
        <Box
          sx={{
            color: "#171717",
            fontSize: "14px",
            fontWeight: "600",
            marginTop: "12px",
            textAlign: "center",
          }}
        >
          {filterData.dialogQuestion}
        </Box>
        <Box
          sx={{
            mt: 3,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="outlined"
            className="createProgram"
            onClick={handleClose}
            sx={{ mr: 2 }}
          >
            {filterData.dialogData[1]}
          </Button>
          <Button
            variant="contained"
            className="createProgram"
            onClick={handleConfirm}
            color="primary"
          >
            {filterData.dialogData[2]}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default Dialog;
