import React, { useState } from "react";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import { TextType } from "../../data/const/dataType";
import { editorStyles, onScreenClick } from "../../data/utils/functions";
import Close from "./components/Close";
import Logo from "./components/Logo";

interface FormType {
  titleForm: TextType;
  question1: TextType;
  error10: TextType;
  label10: TextType;
  question2: TextType;
  error20: TextType;
  error21: TextType;
  label20: TextType;
  label21: TextType;
  question3: TextType;
  error30: TextType;
  error31: TextType;
  label30: TextType;
  label31: TextType;
  question4: TextType;
  question5: TextType;
  error40: TextType;
  label40: TextType;
  label41: TextType;
  label42: TextType;
  label43: TextType;
  button: TextType;
  display?: number;
}

interface FormData {
  form: FormType;
}

type StateType = {
  formData: FormData;
};

const Form: React.FC = () => {
  const formData = useSelector((state: StateType) => state.formData);
  const [weight, updateWeight] = useState("kg");
  const [lenght, updateLenght] = useState("cm");
  const [gender, updateGender] = useState("female");
  const [hydrated, updateHydration] = useState("yes");

  return (
    <Box>
      {formData.form.display === 1 && (
        <Box>
          <Close />
          <Logo />
          <Box
            sx={{
              margin: "24px",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#ffffff",
                padding: "24px",
                margin: "12px",
                borderRadius: "16px",
                width: "400px",
                height: "510px",
              }}
            >
              <Box sx={{ borderRadius: 10 }}>
                <Box>
                  <Box
                    sx={{
                      fontSize: "14px",
                      color: "#242424",
                      marginBottom: "4px",
                      fontWeight: "500",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {formData.form.question1.value}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      whiteSpace: "pre-line",
                    }}
                  >
                    <TextField
                      size="small"
                      variant="outlined"
                      placeholder="0"
                      value="21"
                      sx={{
                        width: 100,
                      }}
                    />
                    <Box
                      sx={{
                        color: "#555555",
                        fontSize: "14px",
                        marginLeft: "12px",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {formData.form.label10.value}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      color: "#ff5555",
                      fontSize: "14px",
                      marginTop: "4px",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {formData.form.error10.value}
                  </Box>

                  <Box
                    sx={{
                      fontSize: "14px",
                      color: "#242424",
                      marginBottom: "4px",
                      marginTop: "12px",
                      fontWeight: "500",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {formData.form.question2.value}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <TextField
                      size="small"
                      variant="outlined"
                      placeholder="0"
                      value="75"
                    />
                    <Box sx={{ display: "flex" }}>
                      <Box
                        onClick={() => updateWeight("kg")}
                        sx={[
                          {
                            marginLeft: "12px",
                          },
                          styles.toggle,
                          weight == "kg" && styles.activeToggle,
                        ]}
                      >
                        {formData.form.label20.value}
                      </Box>
                      <Box
                        onClick={() => updateWeight("pound")}
                        sx={[
                          {
                            marginLeft: "-8px",
                          },
                          styles.toggle,
                          weight == "pound" && styles.activeToggle,
                        ]}
                      >
                        {formData.form.label21.value}
                      </Box>
                    </Box>
                  </Box>
                  {weight == "kg" ? (
                    <Box
                      sx={{
                        color: "#ff5555",
                        fontSize: "14px",
                        marginTop: "4px",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {formData.form.error20.value}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        color: "#ff5555",
                        fontSize: "14px",
                        marginTop: "4px",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {formData.form.error21.value}
                    </Box>
                  )}

                  <Box
                    sx={{
                      fontSize: "14px",
                      color: "#242424",
                      marginBottom: "4px",
                      marginTop: "12px",
                      fontWeight: "500",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {formData.form.question3.value}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    {lenght == "cm" ? (
                      <TextField
                        size="small"
                        variant="outlined"
                        placeholder="0"
                        value="110"
                      />
                    ) : (
                      <Box sx={{ flexDirection: "row", display: "flex" }}>
                        <TextField
                          sx={{
                            width: "95px",
                          }}
                          size="small"
                          variant="outlined"
                          placeholder="0"
                          value="5"
                        />
                        <TextField
                          sx={{
                            marginLeft: "10px",
                            width: "90px",
                          }}
                          size="small"
                          variant="outlined"
                          placeholder="0"
                          value="11"
                        />
                      </Box>
                    )}

                    <Box sx={{ display: "flex" }}>
                      <Box
                        onClick={() => updateLenght("cm")}
                        sx={[
                          {
                            marginLeft: "12px",
                          },
                          styles.toggle,
                          lenght == "cm" && styles.activeToggle,
                        ]}
                      >
                        {formData.form.label30.value}
                      </Box>
                      <Box
                        onClick={() => updateLenght("ft")}
                        sx={[
                          {
                            marginLeft: "-8px",
                          },
                          styles.toggle,
                          lenght == "ft" && styles.activeToggle,
                        ]}
                      >
                        {formData.form.label31.value}
                      </Box>
                    </Box>
                  </Box>
                  {lenght == "cm" ? (
                    <Box
                      sx={{
                        color: "#ff5555",
                        fontSize: "14px",
                        marginTop: "4px",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {formData.form.error30.value}
                    </Box>
                  ) : (
                    <>
                      <Box
                        sx={{
                          color: "#ff5555",
                          fontSize: "14px",
                          marginTop: "4px",
                          whiteSpace: "pre-line",
                        }}
                      >
                        {formData.form.error31.value}
                      </Box>
                      <Box
                        sx={{
                          color: "#ff5555",
                          fontSize: "14px",
                          marginTop: "4px",
                          whiteSpace: "pre-line",
                        }}
                      >
                        {formData.form.error40.value}
                      </Box>
                    </>
                  )}

                  <Box
                    sx={{
                      fontSize: "14px",
                      color: "#242424",
                      marginBottom: "4px",
                      marginTop: "12px",
                      fontWeight: "500",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {formData.form.question4.value}
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Box
                      onClick={() => updateGender("female")}
                      sx={[
                        styles.gender,
                        gender == "female" && styles.activeGender,
                      ]}
                    >
                      {formData.form.label40.value}
                    </Box>
                    <Box
                      onClick={() => updateGender("male")}
                      sx={[
                        styles.gender,
                        gender == "male" && styles.activeGender,
                      ]}
                    >
                      {formData.form.label41.value}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      fontSize: "14px",
                      color: "#242424",
                      marginBottom: "4px",
                      marginTop: "12px",
                      fontWeight: "500",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {formData.form.question5.value}
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Box
                      onClick={() => updateHydration("yes")}
                      sx={[
                        styles.gender,
                        hydrated == "yes" && styles.activeGender,
                      ]}
                    >
                      {formData.form.label42.value}
                    </Box>
                    <Box
                      onClick={() => updateHydration("no")}
                      sx={[
                        styles.gender,
                        hydrated == "no" && styles.activeGender,
                      ]}
                    >
                      {formData.form.label43.value}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              margin: "24px 40px",
              textAlign: "center",
            }}
          >
            <Box
              onClick={() => onScreenClick(6)}
              sx={{
                ...editorStyles(formData.form.button),
                borderWidth: 1,
                borderStyle: "solid",
                borderRadius: 2,
                padding: "8px 32px",
                cursor: "pointer",
                display: "inline-block",
                whiteSpace: "pre-line",
              }}
            >
              {formData.form.button.value}
            </Box>
          </Box>
        </Box>
      )}
      {formData.form.display === 2 && (
        <Box sx={{ color: "#ffffff" }}>Page Hide</Box>
      )}
    </Box>
  );
};

const styles = {
  toggle: {
    fontSize: "14px",
    background: "#F5F5F5",
    borderColor: "#dddddd",
    fontWeight: "bold",
    borderWidth: "1px",
    color: "#999999",
    padding: "8px 16px",
    borderRadius: "8px",
    borderStyle: "solid",
    whiteSpace: "pre-line",
  },
  activeToggle: {
    background: "#777777",
    color: "#ffffff",
  },
  activeGender: {
    background: "#21FFAC",
    color: "#616161",
  },
  gender: {
    fontSize: "14px",
    marginRight: "12px",
    background: "#F5F5F5",
    borderColor: "#dddddd",
    borderWidth: "1px",
    color: "#999999",
    padding: "8px 16px",
    borderRadius: "8px",
    borderStyle: "solid",
    fontWeight: "bold",
    whiteSpace: "pre-line",
  },
};

export default Form;
