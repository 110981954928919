import React from "react";

const WaterDropLow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="400"
      height="200"
      viewBox="0 0 100 200"
    >
      {/* Define a linear gradient for the water color */}
      <linearGradient id="waterGradient" x1="0%" y1="100%" x2="0%" y2="0%">
        <stop offset="0%" style={{ stopColor: "#0074d9", stopOpacity: 1 }} />
        <stop offset="75%" style={{ stopColor: "#0074d9", stopOpacity: 0.5 }} />
      </linearGradient>

      {/* Create the darker upside-down water drop shape */}
      <path
        d="M50,150 C78.9,150 100,128.9 100,100 C100,62.4 50,0 50,0 C50,0 0,62.4 0,100 C0,128.9 21.1,150 50,150Z"
        fill="#0074d9"
        opacity="0.6"
      />

      {/* Create a mask for the 30% water level */}
      <mask id="waterMask">
        <rect width="100%" height="100%" fill="white" />
        <path
          d="M-10,100 Q20,85 50,100 Q80,115 110,100 L110,150 L-10,150 Z"
          fill="black"
        />
      </mask>

      {/* Apply the mask to the water drop shape */}
      <path
        d="M50,150 C78.9,150 100,128.9 100,100 C100,62.4 50,0 50,0 C50,0 0,62.4 0,100 C0,128.9 21.1,150 50,150Z"
        fill="#0074d9"
        mask="url(#waterMask)"
      />
    </svg>
  );
};

export default WaterDropLow;
