import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TitleForm from "./components/TitleForm";
import AccordionForms from "./components/AccordionForms";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Input, Switch, Tab, Tabs } from "@mui/material";
import Editor from "./components/Editor";
import { TextType, ResultWidget } from "../../data/const/dataType";
import { widgetsData } from "../../data/const/widgetsData";
import { actions } from "../../state";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Swal from "sweetalert2";
import ColorPicker from "./components/ColorPicker";

interface ResultsType {
  titleForm: string;
  listWidgets: ResultWidget[];
  selectedWidget: ResultWidget[];
  activeIndex: number;
  header: TextType;
  footer: TextType;
  activeForm: number;
  tabDisplay: number;
  activeMSIIndex: number;
  showDownloadButton: boolean;
}

interface FormData {
  results: ResultsType;
}

type StateType = {
  formData: FormData;
};

let iconWidgets = ["HR_BPM", "AGE_SKIN", "W2HR", "FACE_AGE"];

const Results: React.FC = () => {
  const formData = useSelector((state: StateType) => state.formData);

  const dispatch = useDispatch();

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    dispatch(
      actions.setForm({
        ...formData,
        results: {
          ...formData.results,
          tabDisplay: newValue,
        },
      })
    );
  };

  const handleActiveWidget = (event: SelectChangeEvent) => {
    if (event.target.value === "new") {
      let remainingWidgets = formData?.results?.listWidgets?.filter(
        (item) =>
          !formData?.results?.selectedWidget?.some((w) => w.code === item.code)
      );

      if (remainingWidgets?.length != 0) {
        dispatch(
          actions.setForm({
            ...formData,
            startForm: true,
            results: {
              ...formData.results,
              activeForm: formData?.results?.selectedWidget?.length,
              selectedWidget: [
                ...formData.results.selectedWidget,
                {
                  ...JSON.parse(JSON.stringify(remainingWidgets[0])),
                },
              ],
            },
          })
        );
      } else {
        // Display error no more widget
      }
    } else {
      dispatch(
        actions.setForm({
          ...formData,
          results: {
            ...formData.results,
            activeForm: event.target.value,
          },
        })
      );
    }
  };

  const handleChangeWidget = (
    event: SelectChangeEvent,
    id: number,
    index: number
  ) => {
    let widget = formData?.results?.selectedWidget?.find(
      (w) => w.code == event?.target?.value
    );
    if (widget) {
      Swal.fire({
        title: "Oops",
        text: `${widget.title} is already configured, Do you want to change order?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Change Order",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          handleChangeOrder(event, index);
        }
      });
    }
    // @ts-ignore
    else if (event?.target?.value != id) {
      const nextWidget = formData.results.listWidgets.find(
        (w) => w.code == event.target.value
      );
      if (nextWidget) {
        let selectedWidget: any[] = [...formData.results.selectedWidget];

        selectedWidget[index] = JSON.parse(JSON.stringify(nextWidget));

        dispatch(
          actions.setForm({
            ...formData,
            startForm: true,
            results: {
              ...formData.results,
              selectedWidget,
            },
          })
        );
      }
    }
  };

  const handleChangeOrder = (
    event: SelectChangeEvent,
    currentIndex: number
  ) => {
    let requiredIndex = formData?.results?.selectedWidget?.findIndex(
      (w) => w.code == event?.target?.value
    );

    let updatedList = JSON.parse(
      JSON.stringify([...formData.results.selectedWidget])
    );
    let temp = updatedList[requiredIndex];
    updatedList[requiredIndex] = updatedList[currentIndex];
    updatedList[currentIndex] = temp;

    dispatch(
      actions.setForm({
        ...formData,
        startForm: true,
        results: {
          ...formData.results,
          selectedWidget: updatedList,
          activeForm: requiredIndex,
        },
      })
    );
  };

  const handleWidgetType = (event: SelectChangeEvent, currentIndex: number) => {
    let updatedList = JSON.parse(
      JSON.stringify([...formData.results.selectedWidget])
    );
    updatedList[currentIndex].type = event?.target?.value;

    dispatch(
      actions.setForm({
        ...formData,
        startForm: true,
        results: {
          ...formData.results,
          selectedWidget: updatedList,
          activeMSIIndex: 0,
        },
      })
    );
  };

  const showWidgets = (code: string) => {
    return widgetsData[code.toLowerCase()];
  };

  const deleteWidget = (index: number) => {
    if (formData?.results?.selectedWidget?.length > 1) {
      let updatedList = JSON.parse(
        JSON.stringify([...formData.results.selectedWidget])
      );
      updatedList.splice(index, 1);
      dispatch(
        actions.setForm({
          ...formData,
          startForm: true,
          results: {
            ...formData.results,
            ...(updatedList?.length > 0 &&
            updatedList?.length > formData?.results.activeForm
              ? {}
              : { activeForm: 0 }),
            selectedWidget: updatedList,
          },
        })
      );
    } else {
      // some error
    }
  };

  const handleStressIndex = (event: SelectChangeEvent) => {
    let stressWidget = formData?.results?.selectedWidget?.find(
      (w) => w.code == "MSI"
    );
    if (stressWidget) {
      dispatch(
        actions.setForm({
          ...formData,
          results: {
            ...formData.results,
            activeMSIIndex: stressWidget?.indexes?.find(
              (i) => i.number == event?.target?.value
            )?.status,
          },
        })
      );
    }
  };

  const updateWidgetDetails = (event: any, type: "state" | "explaination") => {
    let stressWidgetIndex = formData?.results?.selectedWidget?.findIndex(
      (w) => w.code == "MSI"
    );

    if (stressWidgetIndex > -1) {
      let stressWidgetList = [...formData.results.selectedWidget];
      stressWidgetList[stressWidgetIndex].indexes[
        formData?.results?.activeMSIIndex || 0
      ][`${type}`] = event?.target?.value;

      dispatch(
        actions.setForm({
          ...formData,
          startForm: true,
          results: {
            ...formData.results,
            selectedWidget: stressWidgetList,
          },
        })
      );
    }
  };

  const toggleDownloadButton = () =>
    dispatch(
      actions.setForm({
        ...formData,
        startForm: true,
        results: {
          ...formData.results,
          showDownloadButton: !formData?.results?.showDownloadButton,
        },
      })
    );

  const toggleIcon = (index: number) => {
    let selectedWidget = formData.results.selectedWidget;

    selectedWidget[index] = {
      ...selectedWidget[index],
      showIcon: !selectedWidget[index].showIcon,
    };
    dispatch(
      actions.setForm({
        ...formData,
        startForm: true,
        results: {
          ...formData.results,
          selectedWidget,
        },
      })
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <TitleForm title={formData.results.titleForm} />
      <Box className="scroll-form-accordion">
        <AccordionForms title="Add a header">
          <Typography className="subtitle-accordion">Title</Typography>
          <Editor form="results" field="header" rows={1} />
        </AccordionForms>
        <AccordionForms title="Customize the Results to be shown" subtitle="">
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <Select
                  size="small"
                  sx={{
                    paddingBottom: "0px",
                  }}
                  value={formData.results.activeForm.toString()}
                  onChange={handleActiveWidget}
                >
                  {formData.results.selectedWidget.map((e: ResultWidget, i) => {
                    return (
                      <MenuItem key={i} value={i}>
                        Widget {i + 1}
                      </MenuItem>
                    );
                  })}
                  {formData.results.selectedWidget?.length < 7 && (
                    <MenuItem value="new" sx={{ bgcolor: "#eeeeee" }}>
                      <Box display="flex" alignItems="center">
                        <AddIcon style={{ fontSize: "24px" }} />
                        <Box ml={1}>Add Result</Box>
                      </Box>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
          </Box>

          {formData.results.selectedWidget.map((item, i) => {
            if (formData.results.activeForm === i) {
              return (
                <Box key={`${item.id}-${item.code}-${i}`}>
                  <Box
                    className="form-title"
                    sx={{
                      fontSize: "24px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Result {i + 1}{" "}
                    <DeleteOutlineIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteWidget(i)}
                    />
                  </Box>
                  <Editor
                    form="results"
                    field={`selectedWidget.${i}`}
                    rows={1}
                    onlyColor
                  />
                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={formData.results.tabDisplay}
                        onChange={handleTabChange}
                        aria-label="basic tabs example"
                      >
                        <Tab
                          label="Result Display"
                          sx={{
                            display: "flex",
                            alignItems: "baseline",
                            width: "50%",
                            maxWidth: "50%",
                            textTransform: "none",
                          }}
                        />
                        <Tab
                          label="Result Explanation"
                          sx={{
                            display: "flex",
                            alignItems: "baseline",
                            width: "50%",
                            maxWidth: "50%",
                            textTransform: "none",
                          }}
                        />
                      </Tabs>
                    </Box>
                    <Box
                      role="tabpanel"
                      hidden={formData.results.tabDisplay !== 0}
                    >
                      <Box
                        style={{
                          margin: "24px 0 0",
                        }}
                      >
                        <Box sx={{ width: "100%" }}>
                          <FormControl fullWidth>
                            <InputLabel id={`resultSelect-${item.id}`}>
                              Widget
                            </InputLabel>
                            <Select
                              size="small"
                              id={`resultSelect-${item.id}`}
                              value={item.code}
                              label="Widget"
                              onChange={(e) =>
                                handleChangeWidget(e, item.id, i)
                              }
                            >
                              {formData.results.listWidgets.map((e, i) => {
                                return (
                                  <MenuItem value={e.code} key={i}>
                                    {e.title}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Box>
                        {formData.results.selectedWidget?.length > 1 && (
                          <Box sx={{ width: "100%", marginTop: "20px" }}>
                            <FormControl fullWidth>
                              <InputLabel id={`Order-${item.id}`}>
                                Order
                              </InputLabel>
                              <Select
                                size="small"
                                id={`Order-${item.id}`}
                                value={item.code}
                                label="Widget"
                                onChange={(e) => handleChangeOrder(e, i)}
                              >
                                {formData.results.selectedWidget.map((e, i) => {
                                  return (
                                    <MenuItem value={e.code} key={i}>
                                      {i + 1}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Box>
                        )}
                        {item.code == "MSI" && (
                          <Box sx={{ width: "100%", marginTop: "20px" }}>
                            <FormControl fullWidth>
                              <InputLabel id={`MSI-Type`}>Type</InputLabel>
                              <Select
                                size="small"
                                id={`MSI-Typeid`}
                                value={item.type}
                                label="Widget"
                                onChange={(e) => handleWidgetType(e, i)}
                              >
                                <MenuItem value={"graph"} key={i}>
                                  Graph
                                </MenuItem>
                                <MenuItem value={"images"} key={i}>
                                  Images
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                        )}
                        <Typography className="subtitle-accordion">
                          Add Title Result
                        </Typography>
                        <Editor
                          form="results"
                          field={`selectedWidget.${i}.titleWidget`}
                          id={item.id}
                          rows={1}
                        />
                        {iconWidgets.includes(item.code) && (
                          <Box
                            sx={{
                              flexDirection: "row",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography className="subtitle-accordion">
                              Show Icon
                            </Typography>
                            <Switch
                              checked={item.showIcon}
                              onChange={() => toggleIcon(i)}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          </Box>
                        )}
                        {(item.code == "MSI" || item.code == "HYD") && (
                          <>
                            <Typography className="subtitle-accordion-form">
                              Enter Label for stress index 0 - 2.9
                            </Typography>
                            <Editor
                              form="results"
                              field={`selectedWidget.${i}.lowValue`}
                              rows={1}
                              format="hide"
                            />
                            <Typography className="subtitle-accordion-form">
                              Enter Label for stress index 3.0 - 5
                            </Typography>
                            <Editor
                              form="results"
                              field={`selectedWidget.${i}.highValue`}
                              id={item.id}
                              rows={1}
                              format="hide"
                            />
                          </>
                        )}

                        <Box className="form-title">Customize the button</Box>
                        <Box className="form-subtitle">Add Button Text</Box>
                        <Editor
                          form="results"
                          field={`selectedWidget.${i}.button`}
                          id={item.id}
                          rows={1}
                          format="button"
                        />
                      </Box>
                    </Box>
                    <Box
                      role="tabpanel"
                      hidden={formData.results.tabDisplay !== 1}
                    >
                      <Box
                        style={{
                          margin: "24px 0 0",
                        }}
                      >
                        {item?.code == "MSI" && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <Box pr={"10px"}>
                              <Box
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  lineHeight: "19.6px",
                                  marginBottom: "10px",
                                }}
                              >
                                Choose Range
                              </Box>
                              <Select
                                size="small"
                                sx={{
                                  paddingBottom: "0px",
                                  width: "132px",
                                }}
                                value={
                                  item?.indexes[
                                    formData?.results?.activeMSIIndex || 0
                                  ]?.number
                                }
                                onChange={handleStressIndex}
                              >
                                {item.type == "graph"
                                  ? item?.indexes.map((e) => {
                                      return (
                                        <MenuItem
                                          key={e.status}
                                          value={e.number}
                                        >
                                          {e.number}
                                        </MenuItem>
                                      );
                                    })
                                  : [item.indexes[0], item.indexes[4]]?.map(
                                      (e) => {
                                        return (
                                          <MenuItem
                                            key={e.status}
                                            value={e.number}
                                          >
                                            {e.status == 0 ? "Low" : "High"}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                              </Select>
                            </Box>
                            <Box width={"100%"}>
                              <Box
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  lineHeight: "19.6px",
                                  marginBottom: "10px",
                                }}
                              >
                                Range state
                              </Box>
                              <Box
                                sx={{
                                  borderRadius: "4px",
                                  borderColor: "#C0C0C0",
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  paddingTop: "5px",
                                  paddingBottom: "3px",
                                  paddingLeft: "8px",
                                }}
                              >
                                <Input
                                  size="small"
                                  onChange={(event) =>
                                    updateWidgetDetails(event, "state")
                                  }
                                  value={
                                    item?.indexes[
                                      formData?.results?.activeMSIIndex
                                    ]?.state || ""
                                  }
                                  placeholder="State: Relaxed"
                                  sx={{
                                    width: "100%",
                                    backgroundColor: "white",
                                    border: "none",
                                    "&:before": {
                                      content: "none",
                                    },
                                    "&:after": {
                                      content: "none",
                                    },
                                  }}
                                  key={`state-${formData?.results?.activeMSIIndex}`}
                                />
                              </Box>
                            </Box>
                          </Box>
                        )}
                        <Box className="form-title">Result Explanations</Box>
                        <Editor
                          form="results"
                          field={`selectedWidget.${i}.explanation`}
                          id={item.id}
                          rows={4}
                          code={item?.code}
                          listView
                          {...(item?.code == "MSI"
                            ? {
                                text:
                                  item?.indexes[
                                    formData?.results?.activeMSIIndex
                                  ]?.explaination || "",
                                onChangeText: (event) =>
                                  updateWidgetDetails(event, "explaination"),
                                key: `explanation-${formData?.results?.activeMSIIndex}`,
                              }
                            : {})}
                        />
                        <Box className="form-title">
                          Customize the button explanation
                        </Box>
                        <Box className="form-subtitle">Add Button Text</Box>
                        <Editor
                          form="results"
                          field={`selectedWidget.${i}.explanationButton`}
                          id={item.id}
                          rows={1}
                          format="button"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            }
          })}
        </AccordionForms>
        {formData?.results?.selectedWidget[formData.results.activeForm || 0]
          ?.code == "AGE_SKIN" && (
          <AccordionForms title="Customize Button">
            <Box
              sx={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography className="subtitle-accordion">
                Button Text
              </Typography>
              <Switch
                checked={formData?.results?.showDownloadButton}
                onChange={toggleDownloadButton}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Box>
            <Editor form="results" field="download" rows={1} format="button" />
          </AccordionForms>
        )}
        {!formData?.results?.showDownloadButton && (
          <AccordionForms title="Customize Next Button">
            <Typography className="subtitle-accordion">Button text</Typography>
            <Editor
              form="results"
              field="nextButton"
              rows={1}
              format="button"
            />
          </AccordionForms>
        )}
        <AccordionForms title="Customize disclaimer text">
          <Typography className="subtitle-accordion">
            Disclaimer text
          </Typography>
          <Editor form="results" field="footer" rows={4} />
        </AccordionForms>
      </Box>
    </Box>
  );
};

export default Results;
