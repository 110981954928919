import { Box, Button, TextField, Typography } from "@mui/material";
import aspire2b from "../../data/assets/images/logo.png";
import { OKTACONFIG } from "../../data/const/variables";
import OktaAuth from "@okta/okta-auth-js";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const oktaAuth = new OktaAuth(OKTACONFIG);

const LoginPage: React.FC = () => {
    
    const navigate = useNavigate();

    const handleLogin = () =>{
        console.log(OKTACONFIG);
        oktaAuth.signInWithRedirect();
    }

    useEffect(()=>{
        validateLogin();
    },[])

    const validateLogin = async () =>{
        // console.log(await oktaAuth.isAuthenticated())
        // console.log(await oktaAuth.getAccessToken())
        if(await oktaAuth.isAuthenticated()){
            navigate('/')
        }
    }

    return (
        <Box sx={{
            display:'flex',
            flexDirection:'row',
            background: '#FFFFFF',
            minHeight: '90vh',
        }}>
            <Box sx={{
                padding: '40px',
                flex:1,
                background:'#000000',
                textAlign:'center'
            }}>
                <Typography sx={{ color: '#fff', fontWeight:'bold',}}>Aspire2B Admin portal</Typography>
                <Box sx={{ margin:'20px 0px'}}>
                    <Box sx={{ height:'30%', margin: '8px', maxHeight:'30%'}}>
                        <img src="/assets/images/login-image-1.png"/>
                    </Box>
                    <Box sx={{ height:'30%', margin: '8px', maxHeight:'30%'}}>
                        <img src="/assets/images/login-image-2.png"/>
                    </Box>
                    
                </Box>
            </Box>
            <Box sx={{
                    minHeight:'100vh',
                    flex: 1,
                    padding: '80px',
                    // alignItems:'center',
                    display:'flex',
                    // justifyContent:'center',
                    flexDirection:'column',
                    textAlign: 'left'
                }}>
                
                <Box textAlign='center' marginBottom='40px'>
                    <img src={aspire2b} style={{height:'100px', width:'100px'}}/>
                </Box>
            
                    <Typography sx={{ fontSize: '14px', marginBottom:'12px' }}>Welcome to the Aspire2B Kiosk App Customization Portal. Here, you can customize the application to fit your specific event or presentation needs. Use this platform to adjust images, update language, and change brand colors. Follow the easy steps, make it unique, and create the perfect app experience for your audience.</Typography>
                    <Typography style={{ margin: '12px 0px', fontSize:'24px', fontWeight:'bold', textAlign:'center'}}>Sign in  with you organizational account</Typography>
                    <Typography style={{ margin: '12px 0px', textAlign:'center', fontSize:'16px'}}>This workspace allows you to sign in with your company ID</Typography>
                    <Typography style={{ marginTop: '12px',  color:'#818181'}}>Enter your company mail ID</Typography>
                    <TextField placeholder="Email Address" style={{ margin: '12px 0px'}}/>
                    <Button variant="contained" onClick={handleLogin}>Continue</Button>
            </Box>
        </Box>
    );
}

export default LoginPage;