import { put, select } from "redux-saga/effects";
import { updateProgramme } from "../../data/api/endpoints";
import { actions } from "..";
import { delay } from "lodash";
import Swal from "sweetalert2";
export default function* getUpdateProgrammeSaga(param) {
  try {
    const {
      programmeId,
      programmeName,
      programmeDescription,
      regionName,
      localeName,
    } = param.payload;
    const resp = yield updateProgramme(
      programmeId,
      programmeName,
      programmeDescription,
      regionName,
      localeName
    );
    if (!resp.status || resp.status === 200) {
      const state = yield select();
      yield put(actions.setForm(state.formData));

      let programs = [...state?.filterData?.programs];
      let index = state?.filterData?.programs?.findIndex(
        (item) => item.id === programmeId
      );

      if (programs[index]) {
        programs[index] = {
          ...programs[index],
          title: programmeName,
          regionName: regionName,
          localeName: localeName,
        };
      }

      yield put(
        actions.setFilter({
          ...state.filterData,
          loadingForm: false,
          settingFormDisplay: false,
          programs,
        })
      );

      Swal.fire({
        title: "Success",
        text: "Program details have been successfully updated.",
        icon: "info",
        confirmButtonText: "Continue",
      });

      if (
        !state?.filterData?.regions?.some(
          (region) => region.title === regionName
        )
      ) {
        yield put(actions.getRegions());
      }

      if (
        !state?.filterData?.locales?.some(
          (locale) => locale.title === localeName
        )
      ) {
        yield put(actions.getLocales());
      }
    } else {
      const state = yield select();
      yield put(
        actions.setFilter({
          ...state.filterData,
          apiError: true,
        })
      );
    }
  } catch (error) {
    const state = yield select();
    yield put(
      actions.setFilter({
        ...state.filterData,
        apiError: true,
      })
    );
  }
}
