import React from 'react';
import Box from '@mui/material/Box';
import ErrorIcon from '@mui/icons-material/Error';

const ErrorAPI: React.FC = () => {

  const handleLogout = () => {
    location.reload();
  }

  return (
    <Box sx={{
      display: 'flex', 
      justifyContent: "center",
      alignItems: "center",
      position: "fixed", 
      width: "100%", 
      height: "100%", 
      background: "#FBFCFE",
      zIndex: 999999,
      opacity: '0.8',
    }}>
      
      <Box sx={{ width: '500px', textAlign: 'center' }}>
          <ErrorIcon sx={{ fontSize: '72px', color: 'red', marginBottom: '32px' }}/> 
          <Box>
            Oops! We're sorry but we seem to be experiencing a technical problem at the moment. We apologize for any inconvenience this may cause and appreciate your patience. Please try again later or contact our support team if you need immediate assistance. Thank you for your understanding.
          </Box>
          <Box sx={{color: '#005CBC', marginTop: '32px', cursor: 'pointer', }} onClick={handleLogout}>Reload App</Box>
      </Box>
    </Box>
  );
}

export default ErrorAPI;