import { store } from "../../components/App";
import { actions } from "../../state";

/**
 * Making Deep Property Access Safe in JavaScript
 * @constant
 * @type {function}
 * @param {function} fn
 * @param {data} defaultVal
 * @return {function}
 */

type StylesType = {
  color: string;
  background: string;
  fontSize: number;
  fontWeight: number;
  fontStyle: number;
  textDecoration: number;
  fontFamily: string;
  borderColor: string;
  textAlign: string;
  isList?: boolean;
};

export const editorStylesData = (value: StylesType) => {
  return {
    color: value.color,
    background: value.background,
    borderColor: value.borderColor,
    fontSize: value.fontSize,
    fontFamily: value.fontFamily,
    fontWeight: value.fontWeight,
    fontStyle: value.fontStyle,
    textDecoration: value.textDecoration,
    textAlign: value.textAlign,
    ...(value.isList ? { isList: value.isList } : {}),
  };
};

export const editorStyles = (value: StylesType) => {
  return {
    color: value.color,
    background: value.background,
    borderColor: value.borderColor,
    fontSize: value.fontSize,
    fontFamily: value.fontFamily,
    fontWeight: value.fontWeight ? "bold" : "normal",
    fontStyle: value.fontStyle ? "italic" : "none",
    textDecoration: value.textDecoration ? "underline" : "none",
    textAlign: value.textAlign,
  };
};

export const getSafe = (fn: any, defaultVal: any) => {
  try {
    return fn();
  } catch (e) {
    return defaultVal;
  }
};

export const onScreenClick = (screen: number) => {
  let state: any = store.getState();
  if (state.formData.startForm) {
    store.dispatch(
      actions.setFilter({
        ...state.filterData,
        dialogDisplay: true,
        dialogQuestion: "Do you want to cancel your changes?",
        dialogData: [0, "No", "Yes"],
      })
    );
  } else {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    store.dispatch(
      actions.setForm({
        ...state.formData,
        formActive: screen,
      })
    );

    store.dispatch(
      actions.setFilter({
        ...state.filterData,
        screenLoading: true,
      })
    );
    store.dispatch(
      actions.getProgrammeConfig({
        programmeId: state.formData.activeProgram,
        componentId: screen,
      })
    );
  }
};
