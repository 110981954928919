import React from "react";

interface SpeedometerProps extends React.SVGProps<SVGSVGElement> {
  // Additional props
}

export const SpeedometerHeart: React.FC<SpeedometerProps> = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 400.000000 400.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)"
        fill="rgb(232,63,40)"
        stroke="none"
      >
        <path
          d="M1100 3861 c-539 -57 -972 -455 -1076 -988 -21 -108 -24 -336 -5
   -443 17 -97 63 -238 104 -320 l32 -65 302 -3 301 -2 296 513 c162 282 301 521
   309 530 11 16 39 -61 212 -587 110 -333 202 -606 205 -606 3 0 104 176 224
   391 121 214 222 392 226 394 3 2 91 -142 196 -321 l189 -324 201 0 c193 0 202
   -1 235 -23 18 -12 39 -34 46 -47 19 -37 16 -101 -7 -138 -36 -58 -59 -62 -358
   -62 l-269 0 -104 177 c-57 97 -109 182 -114 190 -8 11 -72 -94 -270 -442 -142
   -250 -262 -452 -266 -448 -4 5 -96 279 -205 611 -109 331 -201 599 -205 595
   -4 -5 -92 -154 -194 -333 -103 -179 -192 -331 -197 -337 -8 -10 -75 -13 -272
   -13 l-261 0 813 -812 812 -813 860 860 c893 893 926 928 1008 1090 43 87 89
   221 109 322 20 105 23 344 4 443 -84 448 -393 807 -819 950 -124 41 -245 60
   -392 60 -262 0 -469 -63 -688 -207 l-83 -55 -72 50 c-238 164 -542 242 -827
   213z"
        />
      </g>
    </svg>
  );
};
