import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { actions } from '../state';

interface FilterData {
    noticeDisplay: boolean,
    noticeMessage: string,
};
type StateType = {
  filterData: FilterData;
};

const Notice: React.FC = () => {

    const filterData = useSelector((state: StateType) => state.filterData);

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(actions.setFilter({
            ...filterData,
            noticeDisplay: false,
            noticeMessage: '',
        }));
    }

    useEffect(()=> {
        if (filterData.noticeDisplay) {
            const timer = setTimeout(()=> {
                handleClose();
            }, 2000);
            return () => clearTimeout(timer); 
        }
    }, [filterData.noticeDisplay])

    return (
        <Box sx={{ width: 500 }}>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={filterData.noticeDisplay}
                onClose={handleClose}
                message={
                    <Box display="flex" alignItems="center">
                      <CheckCircleOutlineIcon style={{ fontSize: 20 }} />
                      <span style={{ marginLeft: 8 }}>
                        {filterData.noticeMessage}
                      </span>
                    </Box>
                  }
                key={"topcenter"}
                className="aspireNoticeInfo"

            />
        </Box>
    );
}

export default Notice;



