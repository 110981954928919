import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import "./styles/upload.css";
import Swal from "sweetalert2";

const imageMaxSize = 5000000; // 5MB
const videoMaxSize = 50000000; // 50 MB

const Upload = ({ type, handleSave, hideButton = false, small = false }) => {
  const formData = useSelector((state) => state.formData);
  const dropRef = React.useRef({});
  dropRef.current = formData;

  useEffect(() => {
    let droppableDiv = dropRef.current;
    droppableDiv.addEventListener("dragover", handleDrag);
    droppableDiv.addEventListener("drop", handleDrop);
    return () => {
      droppableDiv.removeEventListener("dragover", handleDrag);
      droppableDiv.removeEventListener("drop", handleDrop);
    };
  }, []);

  const handleDrag = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      saveInputFile(event.dataTransfer.files, dropRef.current);
    }
  };

  const isImageSelected = (uploadedImageName) => {
    let listFormats = [];
    if (type === "image") {
      listFormats = ["jpeg", "jpg", "png"];
    }
    if (type === "video") {
      listFormats = ["mp4", "mov"];
    }
    return listFormats.includes(
      uploadedImageName.split(".").reverse()[0].toLocaleLowerCase()
    );
  };

  function changeMimeType(source) {
    if (source?.includes("mov")) {
      return source.replace("quicktime", "mp4");
    }
    return source;
  }

  const saveInputFile = (files) => {
    const isImageUploaded = isImageSelected(files[0].name);
    if (isImageUploaded) {
      if (files[0].size < (type === "image" ? imageMaxSize : videoMaxSize)) {
        const fileData = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(fileData);

        reader.onload = () => {
          let source = changeMimeType(reader.result);
          handleSave(source, fileData);
        };
      } else {
        Swal.fire({
          title: "Large file",
          text: `The file you are trying to upload is larger than the allowed size limit (${
            type === "image" ? 5 : 50
          } Mb). Please ensure that your file is within the specified size limit and try again.`,
          icon: "error",
          confirmButtonText: "Okay",
        });
      }
    } else {
      Swal.fire({
        title: "Unsupported file",
        text: `The file you selected is not supported. Only ${
          type == "video" ? "MOV and MP4" : "JPG, JPEG and PNG"
        } file formats are allowed. Please choose a valid file and try again.`,
        icon: "error",
        confirmButtonText: "Okay",
      });
    }
  };

  const imageDragDropContainer = (
    <Grid container>
      <Grid item>
        <div
          className={small ? "uploadSmallImageFrame" : "uploadImageFrame"}
          ref={dropRef}
          data-testid="imageDrag"
        >
          <i className="fas fa-cloud-upload uploadCloudIcon" />
          <InsertDriveFileIcon fontSize="large" style={{ color: "#0096D1" }} />
          {type === "image" && (
            <Box
              sx={
                small && {
                  fontSize: "10px",
                  lineHeight: "14px",
                  textAlign: "center",
                  fontFamily: "Inter",
                  fontWeight: "400",
                }
              }
            >
              {
                "Drag and drop your file here to upload. Max file size 5 Mb.\n(Image only)"
              }
            </Box>
          )}
          {type === "video" && (
            <Box>
              {
                "Drag and drop your file here to upload. Max file size 50 Mb.\n(MP4/MOV only)"
              }
            </Box>
          )}
        </div>
      </Grid>
      {!hideButton && (
        <Grid item xs={12} sm={12} className="replaceBtnGridItem">
          <Button
            sx={{ width: "100%" }}
            variant="outlined"
            color="primary"
            size="medium"
            className="button-label replaceImageButton"
            component="label"
          >
            Select File to Upload
            <input
              type="file"
              style={{ display: "none" }}
              onChange={(e) => {
                saveInputFile([...e.target.files]);
                e.target.value = null;
              }}
              data-testid="imageInputType"
              accept={type === "image" ? "image/*" : "video/*"}
            />
          </Button>
          {/* <Button 
          variant="contained" 
          disabled={!imageUrl} 
          onClick={handleDelete} 
          color="warning" 
          size="medium" 
          component="label"
          className="replaceImageButtonRemove"
          >
           Delete
        </Button> */}
        </Grid>
      )}
    </Grid>
  );

  return (
    <div
      id="banner-image-container"
      className="authoring-banner-image-container"
    >
      <Grid container>
        <Grid item xs={12} sm={12} className="imageUploadContainer">
          {imageDragDropContainer}
        </Grid>
      </Grid>
    </div>
  );
};

export default Upload;
