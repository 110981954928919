import { OKTACONFIG } from "../const/variables";
import OktaAuth from "@okta/okta-auth-js";

const oktaAuth = new OktaAuth(OKTACONFIG);

export const generateToken = async () => {
  return await oktaAuth.getAccessToken();
};

export const getHeaders = async () => {
  const token = await oktaAuth.getAccessToken();
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};
