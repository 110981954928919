import { put, select } from "redux-saga/effects";
import { createProgramme, downloadAssets } from "../../data/api/endpoints";
import { getSafe } from "../../data/utils/functions";
import { actions } from "..";

export default function* getCreateProgrammeSaga(param) {
    try {
      const { programmeName, programmeDescription, regionName, localeName } = param.payload;
      const resp = yield createProgramme(programmeName, programmeDescription, regionName, localeName);
      if (!resp.status || resp.status === 200) {
          const state = yield select();
          const programme = getSafe(() => resp["data"]["data"]);
          const tempPrograms =  {
            "id": programme["id"],
            "title": programme["name"],
            "localeName": programme["locale"]["localeName"],
            "localCode": programme["locale"]["localeCode"],
            "regionName": programme["region"]["regionName"],
            "regionCode": programme["region"]["regionCode"],
            "state": programme["state"],
            "default": programme["default"],
            "self": programme["selfCreated"],
            "brandBackgroundColor": programme["globals"] != null ? programme["globals"]["style"]["brandBackgroundColor"] : '#1E1057',
            "brandLogoBlob":  programme["globals"]["data"]["brandLogoBlob"],
          };
        yield put(actions.setFilter({
          ...state.filterData,
          programs: [tempPrograms, ...state.filterData.programs],
          loadingForm: false,
          openModal: false,
        }));
        const image = yield downloadAssets(tempPrograms["brandLogoBlob"]);
        if (!image.status || image.status === 200) { 
          const state = yield select();
          yield put(actions.setFilter({
            ...state.filterData,
            programs: state.filterData.programs.map((program) => {
              if(program.id === tempPrograms["id"]) {
                  return {
                      ...program,
                      brandLogoBlob: URL.createObjectURL(image)
                  }
              } else {
                  return program;
              }
            }),
            noticeDisplay: true,
            noticeMessage: 'Your program created Successfully',
          }));
        }
      } else {
        const state = yield select();
        yield put(actions.setFilter({
          ...state.filterData,
          loadingForm: false,
          apiError: true,
          openModal: false,
        }));
      }
    } catch (error) {
      const state = yield select();
      yield put(actions.setFilter({
        ...state.filterData,
        loadingForm: false,
        apiError: true,
        openModal: false,
      }));
    }
}