
import React from 'react';
import { Box } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { validateData } from '../data/const/validateData';

interface ValidateProps {
    display: boolean;
    message: string;
}

const Validate: React.FC<ValidateProps> = ({ display, message }) => {

  if(!display) {
    return null;
  }

  return (
    <Box 
      sx={{
        fontSize: "12px",
        color: "#D40020",
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        margin: "4px",
      }}
    >
      <Box sx={{
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
      }}><WarningIcon sx={{fontSize: "16px", marginRight: "4px"}}/></Box>
      <Box>{validateData[message]}</Box>
    </Box>
  );
}

export default Validate;
