import React, { useEffect } from "react";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import reducer from "../state";
import rootSaga from "../state/rootSaga";
import TopBar from "./TopBar";
import Menu from "./Menu";
import Form from "./Form";
import Demo from "./Demo";
import Home from "./Home";
import Notice from "./Notice";
import CreateProgram from "../modules/CreateProgram";
import { OKTACONFIG } from "../data/const/variables";
import OktaAuth from "@okta/okta-auth-js";
import { useNavigate } from "react-router-dom";

const sagaMiddleware = createSagaMiddleware();
export const store = createStore(
  reducer,
  applyMiddleware(
    sagaMiddleware
    // ,createLogger()
  )
);
sagaMiddleware.run(rootSaga);

const defaultTheme = createTheme();

const oktaAuth = new OktaAuth(OKTACONFIG);

const Aspire2bAdmin: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    validateLogin();
  }, []);

  const validateLogin = async () => {
    // console.log(await oktaAuth.getAccessToken());
    if (!(await oktaAuth.isAuthenticated())) {
      navigate("/auth");
    }
  };

  return (
    <div className="aspire2b-admin">
      <ThemeProvider theme={defaultTheme}>
        <Provider store={store}>
          <Box
            sx={{
              display: "flex",
              background: "#f3F7FE",
              width: "100%",
              minHeight: "100vh",
            }}
          >
            <CssBaseline />
            <TopBar />
            <Menu />
            <Home />
            <Demo />
            <Form />
            <CreateProgram />
            <Notice />
          </Box>
        </Provider>
      </ThemeProvider>
    </div>
  );
};

export default Aspire2bAdmin;
