import { put, select } from "redux-saga/effects";
import { cloneProgramme } from "../../data/api/endpoints";
import { actions } from "..";
export default function* getCloneProgrammeSaga(param) {
  const state = yield select();
  try {
    const { programmeId, programmeName, regionName, localeName } =
      param.payload;
    const resp = yield cloneProgramme(
      programmeId,
      programmeName,
      regionName,
      localeName
    );
    if (resp.status === 200) {
      let newProgram = {
        ...state?.filterData?.programs?.find((p) => (p.id = programmeId)),
      };
      newProgram.id = resp?.data?.data || "";
      newProgram.title = programmeName || "";
      newProgram.regionName = regionName || "";
      newProgram.localeName = localeName || "";
      newProgram.self = true;

      yield put(
        actions.setFilter({
          ...state.filterData,
          programs: [newProgram, ...state?.filterData?.programs],
          openModal: false,
          programValue: 0,
          noticeDisplay: true,
          noticeMessage: "Your program cloned Successfully",
        })
      );
    } else {
      throw Error();
    }
  } catch (error) {
    yield put(
      actions.setFilter({
        ...state.filterData,
        apiError: true,
        loadingForm: false,
        openModal: false,
        programValue: 0,
      })
    );
  }
}
