import { put, select } from "redux-saga/effects";
import { actions } from "..";
import { fetchLocale } from "../../data/api/endpoints";
import { getSafe } from "../../data/utils/functions";

export default function* getLocalesSaga() {
  try {
    const resp = yield fetchLocale();
    if (!resp.status || resp.status === 200) {
      const state = yield select();
      const listLocales = getSafe(()=>resp["data"]["data"]) || [];
      yield put(actions.setFilter({
        ...state.filterData,
        locales: listLocales.map((locale, index) =>{
          return {
            "id": locale["localeCode"],
            "title": locale["localeName"],
          }
        }),
        apiError: false,
      }));
    } else {
      const state = yield select();
      yield put(actions.setFilter({
        ...state.filterData,
        apiError: true,
      }));
    }
  } catch (error) {
    const state = yield select();
    yield put(actions.setFilter({
      ...state.filterData,
      apiError: true,
    }));
  }
}