import React from "react";

interface IndicatorProps extends React.SVGProps<SVGSVGElement> {
  // Additional props
}

export const Indicator: React.FC<IndicatorProps> = (props) => {
  return (
    <svg
      height={200}
      viewBox="0 0 501.173 501.173"
      width={200}
      data-name="indicator"
      {...props}
    >
      <path
        d="M256 153.31l24.02 117.45a25.266 25.266 0 00-48.04 0z"
        fill="#6d7486"
      />
      <path
        d="M280.02 270.76A25.266 25.266 0 11256 253.33a25.093 25.093 0 0124.02 17.43z"
        fill="#d4e1f4"
      />
    </svg>
  );
};
