import { put, select } from "redux-saga/effects";
import { updateProgrammeState } from "../../data/api/endpoints";
import { actions } from "..";

export default function* getUpdateProgrammeStateSaga(param) {
  try {
    const { programmeId, programState } = param.payload;
    const resp = yield updateProgrammeState(programmeId, programState);
    if (!resp.status || resp.status === 200) {
      const state = yield select();
      yield put(
        actions.setFilter({
          ...state.filterData,
          apiError: false,
          loading: false,
          screenLoading: false,
          noticeDisplay: true,
          noticeMessage: `Program state updated to ${programState} Successfully`,
        })
      );
      yield put(
        actions.setForm({
          ...state.formData,
          startForm: false,
        })
      );
    } else {
      const state = yield select();
      yield put(
        actions.setFilter({
          ...state.filterData,
          apiError: true,
        })
      );
    }
  } catch (error) {
    const state = yield select();
    yield put(
      actions.setFilter({
        ...state.filterData,
        apiError: true,
      })
    );
  }
}
