import React from "react";

const Meditation = () => {
  return (
    <svg
      version="1.0"
      width="200"
      height="150"
      viewBox="0 0 200.000000 200.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
        fill="#FFFFFF"
        stroke="none"
      >
        <path
          d="M930 1971 c-46 -15 -85 -39 -116 -73 -54 -57 -69 -95 -69 -178 0 -83
15 -121 69 -178 53 -57 101 -77 186 -77 57 0 84 5 115 22 47 25 95 74 121 123
26 49 26 171 0 220 -26 49 -74 98 -121 122 -43 22 -144 33 -185 19z"
        />
        <path
          d="M695 1368 c-84 -30 -113 -71 -229 -323 l-88 -190 -153 -155 c-124
-126 -153 -162 -159 -191 -16 -89 63 -171 151 -154 32 6 65 33 202 168 162
161 164 163 216 272 l52 110 7 -60 c3 -33 8 -107 11 -165 l6 -105 -232 -124
c-244 -132 -268 -151 -284 -228 -16 -77 42 -169 122 -193 36 -11 79 -7 344 29
167 23 320 41 339 41 19 0 172 -18 339 -41 265 -36 308 -40 344 -29 80 24 138
116 122 193 -16 77 -39 95 -284 227 l-232 125 6 105 c3 58 8 132 11 164 l6 59
53 -109 c52 -108 54 -111 216 -271 135 -133 171 -163 201 -168 20 -4 46 -4 57
0 36 11 79 54 91 90 21 64 1 96 -161 260 l-148 150 -88 192 c-108 235 -136
276 -206 308 -51 24 -56 25 -327 24 -167 0 -287 -5 -305 -11z"
        />
      </g>
    </svg>
  );
};

export default Meditation;
