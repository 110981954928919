import React from "react";

const BioAge = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="60.000000pt"
      height="49.000000pt"
      viewBox="0 0 497.000000 490.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,490.000000) scale(0.100000,-0.100000)"
        fill="#808080"
        stroke="none"
      >
        <path
          d="M1322 4200 c-229 -32 -439 -132 -603 -287 -95 -90 -161 -179 -219
          -298 -195 -398 -141 -850 155 -1300 110 -167 191 -263 394 -466 309 -308 510
          -474 1073 -887 197 -144 361 -262 364 -262 9 0 617 446 788 579 241 187 428
          351 637 560 265 265 406 446 515 663 252 501 202 1013 -133 1369 -92 97 -179
          161 -308 224 -272 135 -584 149 -875 39 -159 -59 -324 -185 -434 -331 -63 -82
          -185 -308 -186 -343 -1 -8 -12 11 -25 42 -87 210 -196 362 -348 483 -130 104
          -287 175 -452 205 -84 15 -268 21 -343 10z"
        />
      </g>
    </svg>
  );
};

export default BioAge;
