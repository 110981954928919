  import { put, select } from "redux-saga/effects";
  import { filterProgrammes, downloadAssets } from "../../data/api/endpoints";
  import { getSafe } from "../../data/utils/functions";
  import { actions } from "..";
  export default function* getFilterProgrammesSaga(param) {
      try {
        const { regionCode, localeCode, createdAt, createdBy } = param.payload;
        const resp = yield filterProgrammes(regionCode, localeCode, createdAt, createdBy);
        if (!resp.status || resp.status === 200) {
          const state = yield select();
          const programmeList = getSafe(()=>resp["data"]["data"]) || [];
          const tempPrograms = programmeList.map((programme, index) =>{
            return {
              "id": programme["id"],
              "title": programme["name"],
              "localeName": programme["locale"]["localeName"],
              "localCode": programme["locale"]["localeCode"],
              "regionName": programme["region"]["regionName"],
              "regionCode": programme["region"]["regionCode"],
              "state": programme["state"],
              "default": programme["default"],
              "self": programme["selfCreated"],
              "brandBackgroundColor": programme["globals"] != null ? programme["globals"]["style"]["brandBackgroundColor"] : '#1E1057',
              "brandLogoBlob":  getSafe(()=>programme["globals"]["data"]["brandLogoBlob"]).toString(),
            }
          });
          yield put(actions.setFilter({
            ...state.filterData,
            programs: tempPrograms,
            loading: false,
            loadingFilter: false,
            apiError: false,
          }));

          for(let i = 0; i < tempPrograms.length; i++) {
            const blobData = tempPrograms[i]["brandLogoBlob"];
            if(blobData) {
              try {
                const image = yield downloadAssets(tempPrograms[i]["brandLogoBlob"]);
                if (!image.status || image.status === 200) { 
                  const state = yield select();
                  yield put(actions.setFilter({
                    ...state.filterData,
                    programs: state.filterData.programs.map((program) => {
                      if(program.id === tempPrograms[i]["id"]) {
                          return {
                              ...program,
                              brandLogoBlob: URL.createObjectURL(image)
                          }
                      } else {
                          return program;
                      }
                    })
                  }));
                }
              } catch (error) {
        
              }
            }
          }

        } else {
          const state = yield select();
          yield put(actions.setFilter({
            ...state.filterData,
            loading: false,
            loadingFilter: false,
            apiError: true,
          }));
        }
      } catch (error) {
        const state = yield select();
        yield put(actions.setFilter({
          ...state.filterData,
          loading: false,
          loadingFilter: false,
          apiError: true,
        }));
      }
  }