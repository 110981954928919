import React from "react";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { UploadType } from "../../../data/const/dataType";

interface BrandType {
  logo: UploadType;
  isLandscape?: boolean;
}
interface FormData {
  brand: BrandType;
}

type StateType = {
  formData: FormData;
};

const Logo: React.FC<{ src?: string }> = ({ src }) => {
  const formData = useSelector((state: StateType) => state.formData);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "24px",
      }}
    >
      <img
        src={src || formData.brand.logo.src}
        style={
          !formData.brand.isLandscape
            ? { width: "60px", height: "60px", objectFit: "contain" }
            : { width: "120px", height: "60px", objectFit: "contain" }
        }
        alt=""
      />
    </Box>
  );
};

export default Logo;
