// url for API request (no backslash at end)
// export const API_URL = "https://api-na.dev.mypepsico.com/it/d2c/v1/aspire2b/admin";
export const API_URL =
  "https://apim-na.dev.mypepsico.com/global/aspire2b-digitalassets/v1/assets-service/admin";

// export const API_URL = "https://pepsico-d2c-aspire2b-digitalassets.snt.gw01.aks01.eus.nonprod.azure.intra.pepsico.com/assets-service/admin";
// export const TOKEN = "eyJraWQiOiJFdV9yOHRBN1VHWVJaYk5WeWJ3aFU2QW5aNGRjYzJwb2k4WkV5S0ZLcUNzIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULmFsSlYyM0wwZXJhNVdLRnJleEVSQ1BnWHB0dEtranlSaVNzNm12a1F4VGMiLCJpc3MiOiJodHRwczovL3NlY3VyZS5pdGUucGVwc2ljby5jb20vb2F1dGgyL2RlZmF1bHQiLCJhdWQiOiJhcGk6Ly9kZWZhdWx0IiwiaWF0IjoxNjg4NzI1NTQxLCJleHAiOjE2ODg3MjkxNDEsImNpZCI6IjBvYTFxa3djdDcyZzY2UTE5MGg4IiwidWlkIjoiMDB1MXFibDhwOGsydGtNY3YwaDgiLCJzY3AiOlsiZW1haWwiLCJwcm9maWxlIiwib3BlbmlkIl0sImF1dGhfdGltZSI6MTY4ODcyNTUzOSwic3ViIjoiU3VuZGFyYXZlbC5NZWVuYWtzaGlzdW5kYXJhbS5Db250cmFjdG9yQHBlcHNpY28uY29tIiwiZ3BpZCI6IjA5Mzk3NDQ5IiwiR1BJRCI6IjA5Mzk3NDQ5IiwiRmlyc3ROYW1lIjoiU3VuZGFyYXZlbCIsInBlcGFwcG1pZWJhY2hyb2xlcyI6W10sInBlcGFwcG1pZWJhY2h3YXJlaG91c2UiOltdLCJwZXBSZWdpc3RlcmVkIjoidHJ1ZSIsIkxhc3ROYW1lIjoiTWVlbmFrc2hpc3VuZGFyYW0iLCJsb2NhbGUiOiJlbl9VUyIsImVtYWlsIjoiU3VuZGFyYXZlbC5NZWVuYWtzaGlzdW5kYXJhbS5Db250cmFjdG9yQHBlcHNpY28uY29tIn0.kvRAn0d9usi203efUgZVFy_a2u1BrYW5wfC5Ta77r4hJd_GNj38hg5ttLXCc6mS71J-0Lr4mG59BpDF3M7vjV1cW8-7Y_XSavDcLleQ4cNuOimmasRKbfkHFBTDTJH-91BtY5X6E3o1K5Z7viDDzvvZzreypwCV7trDCwVZ-0LcpkrKog7_cmwQlx1mjgKXbi-SpsuHPWcyxDqZ78fwXmitmgnznKlv4VNVUyOx3S9eIuL6uDsTetylUBZvvQUz0Yw1gjpuHsGsfQh_NGwfLu25Z8XA-E6breYDXcFEHmT2Hk9amhUdcw3wZj-5omiq4XPyAqe44c1E8eFkQ_D2GIg";

export const OKTA_ISSUER = "https://secure.ite.pepsico.com/oauth2/default";
export const OKTA_CLIENT_ID = "0oa1qkwct72g66Q190h8";
export const OKTA_REDIRECT_URI = `${window.location.origin}/login`;
export const OKTA_LOGOUT_URI = `${window.location.origin}/logout`;

export const OKTACONFIG = {
  clientId: OKTA_CLIENT_ID,
  issuer: OKTA_ISSUER,
  redirectUri: OKTA_REDIRECT_URI,
  scopes: ["openid", "profile", "email"],
  logoutUrl: OKTA_LOGOUT_URI,
  pkce: true,
  disableHttpsCheck: false,
};
