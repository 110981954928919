import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextType, ResultWidget } from "../../../data/const/dataType";
import Box from "@mui/material/Box";
import { SpeedometerHeart } from "./SpeedometerHeart";
import { Face } from "./Face";
import { SpeedometerStress } from "./SpeedometerStress";
import { editorStyles, onScreenClick } from "../../../data/utils/functions";
import { Indicator } from "./Indicator";
import { actions } from "../../../state";
import Meditation from "./Meditation";
import WaterDropLow from "./HydrationLow";
import { Typography } from "@mui/material";
import BioAge from "./BioAge";

type WidgetProps = {
  item: ResultWidget;
};

interface ResultsType {
  download: TextType;
  showDownloadButton: boolean;
}

interface FormData {
  results: ResultsType;
  brand: any;
  product: any;
}

type StateType = {
  formData: FormData;
};

let numberWidgets = ["HR_BPM", "AGE_SKIN", "W2HR", "BMI", "FACE_AGE"];

let title = {
  HR_BPM: "80",
  AGE_SKIN: "29",
  W2HR: "56%",
  BMI: "24.3",
  FACE_AGE: "29",
};
let subTitle = {
  HR_BPM: "bpm",
  AGE_SKIN: "-5 years",
  W2HR: "",
  BMI: "kg/m",
  FACE_AGE: "-5 years",
};
const WidgetResult: React.FC<WidgetProps> = ({ item }) => {
  const formData = useSelector((state: StateType) => state.formData);

  const dispatch = useDispatch();

  const handleExplainScreen = () => {
    dispatch(
      actions.setForm({
        ...formData,
        results: {
          ...formData.results,
          tabDisplay: 1,
        },
      })
    );
  };

  const getWidgetValue = (item: any) => {
    if (item.code == "MSI") {
      if (item.type === "images") {
        return item.value > 2.9
          ? item?.highValue?.value || ""
          : item?.lowValue?.value || "";
      }
      return item?.indexes[2]?.state || "-- State --";
    } else if (item.code == "HYD") {
      return item.value > 2.9
        ? item?.highValue?.value || ""
        : item?.lowValue?.value || "";
    }
    return item.value;
  };

  const handleDownloadButton = () =>
    onScreenClick(formData?.product?.display ? 8 : 9);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "center",
        margin: "0 48px",
        background: item?.background, //236,49,35
        // borderWidth: 1,
        // borderStyle: "solid",
        borderRadius: "16px",
        height: "390px",
      }}
    >
      <Box
        sx={{
          ...editorStyles(item.titleWidget),
          background: "transparent",
          whiteSpace: "pre-line",
          marginTop: "10px",
        }}
      >
        {item.titleWidget.value}
      </Box>
      <Box>
        {(item.code === "HYD" || item.code == "MSI") && (
          <>
            <Box
              sx={{
                marginTop: "0px",
                borderRadius: "6px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {item.code == "MSI" && item.type !== "images" && (
                <Box
                  style={{
                    position: "absolute",
                    bottom: "120px",
                  }}
                >
                  <Indicator />
                </Box>
              )}
              {item.code == "HYD" && <WaterDropLow />}
              {item.code === "MSI" ? (
                item.type == "images" ? (
                  <Meditation />
                ) : (
                  <img
                    src={require("../../../data/assets/images/stressindex.png")}
                    style={{ width: 200, height: 200 }}
                    alt=""
                  />
                )
              ) : null}
            </Box>
            {item.code == "MSI" && item.type !== "images" && (
              <Box
                sx={{
                  ...editorStyles(item.titleWidget),
                  opacity: 0.9,
                  fontWeight: "600",
                  background: "transparent",
                  whiteSpace: "pre-line",
                  marginTop: "-20px",
                }}
              >
                {item.value}
              </Box>
            )}
            <Box
              sx={{
                ...editorStyles(item.titleWidget),
                marginTop: item.code == "MSI" ? "10px" : 0,
                fontSize:
                  item.code !== "MSI" && item.code !== "HYD" ? "28px" : "20px",
                opacity: 0.9,
                fontWeight: "600",
                background: "transparent",
                whiteSpace: "pre-line",
                marginLeft: 4,
                marginRight: 4,
              }}
            >
              {getWidgetValue(item)}
            </Box>
          </>
        )}

        {numberWidgets.includes(item.code) && (
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                ...editorStyles(item.titleWidget),
                fontSize: "130px",
                opacity: 0.9,
                fontWeight: "600",
                background: "transparent",
                lineHeight: "130px",
              }}
            >
              {
                //@ts-ignore
                title[item?.code]
              }
            </Box>
            <Typography
              sx={{
                ...editorStyles(item.titleWidget),
                background: "transparent",
                fontWeight: "500",
                fontSize: "40px",
                flexDirection: "row",
              }}
            >
              {
                //@ts-ignore
                subTitle[item.code]
              }
              {item.code == "BMI" && <sup>2</sup>}
            </Typography>

            {item.code === "HR_BPM" && item?.showIcon && <SpeedometerHeart />}
            {item.code === "AGE_SKIN" && item?.showIcon && (
              <Box
                sx={{
                  margin: "10px 10px 0 0",
                }}
              >
                <BioAge />
              </Box>
            )}

            {item.code === "FACE_AGE" && item?.showIcon && (
              <Box
                sx={{
                  margin: "10px 10px 0 0",
                }}
              >
                <Face />
              </Box>
            )}
            {item.code === "W2HR" && item?.showIcon && (
              <img
                src={require("../../../data/assets/images/tape.png")}
                style={{ width: 90, height: 90, marginTop: 20 }}
                alt=""
              />
            )}
          </Box>
        )}
      </Box>
      <Box>
        <Box
          sx={{
            margin: "0 40px",
            textAlign: "center",
            marginBottom: "12px",
          }}
        >
          <Box
            onClick={() => handleExplainScreen()}
            sx={{
              ...editorStyles(item.button),
              borderWidth: "1px",
              borderStyle: "solid",
              borderRadius: "8px",
              padding: "8px 32px",
              cursor: "pointer",
              display: "inline-block",
              whiteSpace: "pre-line",
            }}
          >
            {item.button.value}
          </Box>
        </Box>
        {formData?.results?.showDownloadButton && item.code == "AGE_SKIN" ? (
          <Box
            sx={{
              marginTop: "8px",
              textAlign: "center",
              borderRadius: "12px",
              marginBottom: "12px",
            }}
          >
            <Box
              onClick={handleDownloadButton}
              sx={{
                ...editorStyles(formData.results.download),
                borderWidth: "1PX",
                borderStyle: "solid",
                borderRadius: "8px",
                padding: "8px 32px",
                cursor: "pointer",
                display: "inline-block",
                whiteSpace: "pre-line",
              }}
            >
              {formData.results.download.value}
            </Box>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default WidgetResult;
