import React from 'react';
import { Box } from '@mui/material';

interface TitleFormProps {
  title: string;
}

const TitleForm: React.FC<TitleFormProps> = ({ title }) => {
  return (
    <Box 
      sx={{
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        marginTop: "64px",
        background: "#EFF5FC",
        padding: "16px",
        color: "#3A3A3A",
        fontWeight: "700",
        fontSize: "18px",
      }}
    >
      {title}
    </Box>
  );
}

export default TitleForm;
