import React, { ReactNode } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface AccordionFormsProps {
  title: string;
  subtitle?: string;
  children: ReactNode;
}

const AccordionForms: React.FC<AccordionFormsProps> = ({ title, subtitle, children }) => {
  return (
    <Accordion 
      disableGutters 
      elevation={0} 
      sx={{ 
        '&::before': { display: 'none' }, 
        borderBottom: '2px solid #EFF5FC'
        }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
        >
          <Typography sx={{
            fontSize: "14px",
            fontWeight: "700",
            color: "#616161"
          }}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            {children}
        </AccordionDetails>
    </Accordion>
  )
}

export default AccordionForms;
