import React from "react";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { TextType } from "../../data/const/dataType";
import { editorStyles, onScreenClick } from "../../data/utils/functions";
import Logo from "./components/Logo";
import Close from "./components/Close";
import defaultLogo from "../../data/assets/images/placeholder-image.png";
import ClearIcon from "@mui/icons-material/Clear";
import { actions } from "../../state";

interface TermsConditions {
  titleForm: string;
  header: TextType;
  content: TextType;
  image: any;
  button: TextType;
  contentBottom: TextType;
}

interface FormData {
  termsConditions: TermsConditions;
  brand: any;
}

type StateType = {
  formData: FormData;
};

const TermsConditions: React.FC = () => {
  const formData = useSelector((state: StateType) => state.formData);
  const dispatch = useDispatch();

  const removeImage = () => {
    dispatch(
      actions.setForm({
        ...formData,
        startForm: true,
        termsConditions: {
          ...formData.termsConditions,
          image: {
            src: "",
            upload: null,
            blob: "",
            link: "",
          },
        },
      })
    );
  };
  return (
    <Box>
      <Close />
      <Logo />
      <Box
        sx={{
          ...editorStyles(formData.termsConditions.header),
          borderWidth: 0,
          background: "none",
          borderStyle: "solid",
          borderRadius: 2,
          padding: 0,
          textAlign: "center",
          whiteSpace: "pre-line",
        }}
      >
        {formData.termsConditions.header.value}
      </Box>
      <Box
        sx={{
          margin: "24px 40px",
        }}
      >
        <Box
          sx={{
            ...editorStyles(formData.termsConditions.content),
            // borderWidth: 1,
            // borderStyle: "solid",
            borderRadius: 2,
            padding: 2,
            background: "rgba(0,0,0,0.1)", //236,49,35
          }}
        >
          <Box
            sx={{
              overflow: "scroll",
              maxHeight: "240px",
              whiteSpace: "pre-line",
            }}
          >
            {formData.termsConditions.content.value}
            {formData.termsConditions.image.src != "" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "16px",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    width: 100,
                    height: 100,
                  }}
                >
                  <img
                    src={formData.termsConditions.image.src}
                    width={100}
                    height={100}
                    alt=""
                  />
                  <Box
                    sx={{
                      display: "flex",
                      position: "absolute",
                      width: "30px",
                      height: "30px",
                      top: "-10px",
                      right: "-10px",
                      background: "black",
                      borderRadius: "30px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={removeImage}
                  >
                    <ClearIcon
                      sx={{
                        width: "15px",
                        height: "15px",
                        color: "white",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          margin: "24px 40px",
          textAlign: "center",
        }}
      >
        <Box
          onClick={() => onScreenClick(3)}
          sx={{
            ...editorStyles(formData.termsConditions.button),
            borderWidth: 1,
            borderStyle: "solid",
            borderRadius: 2,
            padding: "8px 32px",
            cursor: "pointer",
            display: "inline-block",
            whiteSpace: "pre-line",
          }}
        >
          {formData.termsConditions.button.value}
        </Box>
      </Box>
      <Box
        sx={{
          ...editorStyles(formData.termsConditions.contentBottom),
          borderWidth: 0,
          background: "none",
          borderStyle: "solid",
          borderRadius: 2,
          padding: "8px 32px",
          opacity: 0.8,
          textAlign: "center",
          whiteSpace: "pre-line",
        }}
      >
        {formData.termsConditions.contentBottom.value}
      </Box>
    </Box>
  );
};

export default TermsConditions;
