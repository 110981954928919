/**
 * List Validate Message
 * @type {Array}
 */

export const validateData: { [key: string]: string } = {
  createProgramName: "Program name i  Required field enter your Program",
  createProgramRegion: "Required field select your Region",
  createProgramRegionOther: "Required field enter your Other Region",
  createProgramLocale: "Required field select your Locale",
  createProgramLocaleOther: "Required field enter your Other Locale",
};

export const apiListScreens: { [key: string]: string } = {
  1: "globals",
  2: "beginScreen",
  3: "howScanWorkScreen",
  4: "termsAndConditionScreen",
  5: "patientInfoScreen",
  6: "globals",
  7: "resultScreen",
  8: "productScreen",
  9: "downloadAppScreen",
};

export const formListScreens: { [key: string]: string } = {
  1: "brand",
  2: "begin",
  3: "howScanWork",
  4: "termsConditions",
  5: "form",
  6: "brand",
  7: "results",
  8: "product",
  9: "end",
};
