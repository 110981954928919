import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Filters from "./Filters";
import { actions } from "../state";
import Loading from "../components/Loading";

type FilterItems = {
  id: number;
  title: string;
  enable: boolean;
  position: number;
  theme: any;
  self: boolean;
  default: boolean;
  starred: boolean;
};

type Program = {
  id: number;
  title: string;
  enable: boolean;
  position: number;
  theme: any;
  self: boolean;
  default: boolean;
  starred: boolean;
  localeName: string;
  localeCode: string;
  regionName: string;
  regionCode: string;
  brandBackgroundColor: string;
  brandLogoBlob: string;
};

type FilterData = {
  regionTitle: string;
  programTitle: string;
  localeTitle: string;
  regions: FilterItems[];
  programs: Program[];
  locales: FilterItems[];
  regionPlaceholder: string;
  programPlaceholder: string;
  localePlaceholder: string;
  regionValue: string;
  programValue: string;
  localeValue: string;
  createNew: string;
  currentProgram: Program;
  createProgram: any;
  loadingFilter: boolean;
};

type FormData = {
  formActive: number;
  create: boolean;
};

type StateType = {
  filterData: FilterData;
  formData: FormData;
};

const Home: React.FC = () => {
  const formData = useSelector((state: StateType) => state.formData);
  const filterData = useSelector((state: StateType) => state.filterData);

  const dispatch = useDispatch();

  const handleCreate = () => {
    dispatch(
      actions.setFilter({
        ...filterData,
        openModal: true,
        startForm: false,
        loadingForm: false,
      })
    );
  };

  const handleActivateProgram = (program: Program) => {
    dispatch(
      actions.setFilter({
        ...filterData,
        loading: true,
      })
    );
    dispatch(
      actions.setForm({
        ...formData,
        preview: true,
      })
    );

    dispatch(
      actions.getProgrammeConfig({
        programmeId: program.id,
        componentId: 1,
      })
    );
  };

  useEffect(() => {
    dispatch(actions.getRegions());
    dispatch(actions.getLocales());
    dispatch(actions.getFilterProgrammes({}));
  }, []);

  return (
    <>
      {formData.formActive > 9 && (
        <Box sx={{ marginTop: "64px", marginLeft: "234px" }}>
          <Box
            sx={{
              color: "#616161",
              fontSize: "24px",
              fontWeight: "700",
              margin: "24px",
            }}
          >
            Home
          </Box>
          <Box
            display="flex"
            width="calc(100vw - 282px)"
            justifyContent="left"
            sx={{
              margin: "24px",
            }}
          >
            <Button
              onClick={handleCreate}
              startIcon={
                <AddIcon
                  style={{
                    fontSize: "48px",
                  }}
                />
              }
              variant="outlined"
              sx={{
                color: "#616161",
                borderColor: "#F3F7FD",
                width: "260px",
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "400",
                textTransform: "capitalize",
                background: "#EFF5FC",
                borderRadius: "10px!important",
                border: "1px solid #DFDFDF",
                padding: "16px 32px",

                boxShadow: "0px 0px 24px rgba(0, 92, 188, 0.3)!important",
              }}
            >
              Create New Program
            </Button>
          </Box>
          <Box
            sx={{
              background: "#ffffff",
              margin: "24px",
              borderRadius: "8px",
              boxShadow: "0px 0px 15px rgba(173,216,230,0.5)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              padding: "24px",
              width: "calc(100vw - 282px)",
              height: "calc(100vh - 274px)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  color: "#616161",
                  fontSize: "18px",
                  fontWeight: "700",
                }}
              >
                {formData.formActive !== 12
                  ? formData.formActive === 10
                    ? "Your Programs"
                    : "All Programs"
                  : "Starred"}
              </Box>

              <Filters type="filter" />
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                marginTop: "32px",
                position: "relative",
                overflow: "scroll",
                border: "1px solid #dddddd",
                padding: "24px",
                minHeight: "calc(100vh - 408px)",
              }}
            >
              {filterData.loadingFilter && <Loading />}
              {(() => {
                const filteredPrograms = filterData.programs.filter((e) => {
                  if (formData.formActive === 10) {
                    return e.self;
                  }
                  if (formData.formActive === 11) {
                    return true;
                  }
                  if (formData.formActive === 12) {
                    return e.starred;
                  } else {
                    return false;
                  }
                });
                if (filteredPrograms.length === 0) {
                  return (
                    <Box sx={{ color: "#616161", fontSize: "14px" }}>
                      There are currently no programs available.
                    </Box>
                  );
                } else {
                  return filteredPrograms.map((e, i) => {
                    if (!e.brandLogoBlob.startsWith("blob")) {
                      return null;
                    }
                    return (
                      <Box
                        key={i}
                        onClick={() => handleActivateProgram(e)}
                        style={{
                          cursor: "pointer",
                          margin: "0 40px 40px 0",
                          boxShadow: "0px 0px 6px rgba(0, 92, 80, 0.3)",
                          border: "1px solid #DFDFDF",
                          position: "relative",
                          borderRadius: "10px",
                          width: "160px",
                        }}
                      >
                        {/* <Box sx={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          zIndex: 99999,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}>  
                         <CircularProgress />
                        </Box> */}
                        <Box
                          sx={{
                            background: e.brandBackgroundColor,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "145px",
                          }}
                        >
                          <img
                            src={e.brandLogoBlob}
                            style={{ maxWidth: "60px" }}
                            alt=""
                          />
                        </Box>
                        <Box
                          sx={{
                            padding: "12px",
                            textAlign: "left",
                            fontSize: "14px",
                            fontWeight: "700",
                            maxWidth: "160px",
                            overflow: "clip",
                          }}
                        >
                          {e.title}
                        </Box>
                        <Box
                          sx={{
                            padding: "0 12px 12px 12px",
                            textAlign: "left",
                            fontSize: "12px",
                          }}
                        >
                          {e.regionName} - {e.localeName}
                        </Box>
                      </Box>
                    );
                  });
                }
              })()}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Home;
