import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Tab, Tabs, Grid } from "@mui/material";
import Modal from "@mui/material/Modal";
import { ProgramType } from "../../data/const/dataType";
import { createDefaultProgram } from "../../data/const/filterData";
import Filters from "../../components/Filters";
import Dialog from "../../components/Dialog";
import Loading from "../../components/Loading";
import Form from "./Form";
import { actions } from "../../state";

interface FilterData {
  programs: ProgramType[];
  createProgram: ProgramType;
  createFormTab: number;
  startForm: boolean;
  dialogData: string[];
  loadingForm: boolean;
  isClone: boolean;
  openModal: boolean;
  currentProgram: any;
  programValue: {
    brandBackgroundColor: string;
    brandLogoBlob: string;
    default: boolean;
    id: string | number;
    localCode: string;
    localeName: string;
    regionCode: string;
    regionName: string;
    self: boolean;
    state: string;
    title: string;
  } | null;
}

interface FormData {
  create: boolean;
  activeProgram: string;
}

type StateType = {
  formData: FormData;
  filterData: FilterData;
};

const CreateProgram: React.FC = () => {
  const formData = useSelector((state: StateType) => state.formData);
  const filterData = useSelector((state: StateType) => state.filterData);

  const dispatch = useDispatch();

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    dispatch(
      actions.setFilter({
        ...filterData,
        createFormTab: newValue,
      })
    );
  };

  const handleClose = () => {
    if (!filterData.startForm) {
      dispatch(
        actions.setFilter({
          ...filterData,
          createFormTab: 0,
          openModal: false,
        })
      );
    } else {
      dispatch(
        actions.setFilter({
          ...filterData,
          dialogDisplay: true,
          dialogQuestion: "Do you want to cancel the information you added?",
          dialogData: [0, "No", "Yes"],
        })
      );
    }
  };

  const handleCreate = () => {
    dispatch(
      actions.setFilter({
        ...filterData,
        validateForm: true,
      })
    );
    if (
      filterData.createProgram.title &&
      filterData.createProgram.region !== 0 &&
      (filterData.createProgram.region !== "other" ||
        filterData.createProgram.regionOther) &&
      filterData.createProgram.locale !== 0 &&
      (filterData.createProgram.locale !== "other" ||
        filterData.createProgram.localeOther)
    ) {
      dispatch(
        actions.setFilter({
          ...filterData,
          loadingForm: true,
        })
      );

      let regionName =
        filterData.createProgram.region != "other"
          ? filterData.createProgram.region
          : filterData.createProgram.regionOther;
      let localeName =
        filterData.createProgram.locale != "other"
          ? filterData.createProgram.locale
          : filterData.createProgram.localeOther;

      dispatch(
        actions.getCreateProgramme({
          programmeName: filterData.createProgram.title,
          programmeDescription: filterData.createProgram.title,
          regionName: regionName,
          localeName: localeName,
        })
      );
    }
  };

  const handleDialog = () => {
    dispatch(
      actions.setFilter({
        ...filterData,
        createFormTab: 0,
        programValue: 0,
        createProgram: createDefaultProgram,
        startForm: false,
        validateForm: false,
        dialogDisplay: false,
        openModal: false,
        dialogQuestion: "",
        dialogData: [0, "", ""],
      })
    );
  };

  const handleClone = () => {
    dispatch(
      actions.setFilter({
        ...filterData,
        loadingForm: true,
      })
    );

    let regionName =
      filterData.createProgram.region != "other"
        ? filterData.createProgram.region
        : filterData.createProgram.regionOther;
    let localeName =
      filterData.createProgram.locale != "other"
        ? filterData.createProgram.locale
        : filterData.createProgram.localeOther;

    dispatch(
      actions.getCloneProgramme({
        programmeId: filterData?.programValue?.id,
        programmeName: filterData.createProgram.title,
        regionName: regionName,
        localeName: localeName,
      })
    );
  };

  return (
    <>
      {filterData.openModal && (
        <>
          <Dialog handleConfirm={handleDialog} />
          <Modal
            open={filterData.openModal}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: "8px",
                boxShadow: "0px 0px 24px rgba(0, 92, 188, 0.08)",
                background: "#FBFCFE",
                minWidth: "1000px",
                maxWidth: "calc(100vw - 50px)",
                maxHeight: "calc(100vh - 50px)",
                overflow: "scroll",
              }}
            >
              {filterData.loadingForm && <Loading />}
              <Box
                sx={{
                  pt: 4,
                  px: 4,
                  pb: 4,
                }}
              >
                <Box
                  sx={{
                    color: "#616161",
                    fontSize: "18px",
                    fontWeight: "700",
                  }}
                >
                  Create New Program
                </Box>
                <Box
                  sx={{
                    color: "#616161",
                    fontSize: "16px",
                    fontWeight: "400",
                    marginTop: "12px",
                  }}
                >
                  You can either choose to start with a new default template or
                  clone from existing program
                </Box>
                <Tabs
                  value={filterData.createFormTab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  sx={{
                    marginTop: "16px",
                    width: "100%",
                    borderBottom: "1px solid #e0e0e0",
                  }}
                >
                  <Tab
                    label="Use Default"
                    sx={{
                      display: "flex",
                      alignItems: "baseline",
                      width: "50%",
                      maxWidth: "50%",
                      textTransform: "none",
                    }}
                  />
                  <Tab
                    label="Clone Existing"
                    sx={{
                      display: "flex",
                      alignItems: "baseline",
                      width: "50%",
                      maxWidth: "50%",
                      textTransform: "none",
                    }}
                  />
                </Tabs>
                <Box role="tabpanel" hidden={filterData.createFormTab !== 0}>
                  <Grid
                    container
                    spacing={4}
                    sx={{
                      marginTop: "12px",
                    }}
                  >
                    <Grid item xs={4}>
                      {filterData.programs.find(
                        (program: ProgramType) => program.default
                      ) && (
                        <Box
                          sx={{
                            background: filterData.programs.find(
                              (program: ProgramType) => program.default
                            )?.brandBackgroundColor,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "300px",
                          }}
                        >
                          <img
                            src={
                              filterData.programs.find(
                                (program: ProgramType) => program.default
                              )?.brandLogoBlob
                            }
                            style={{ maxWidth: "100px" }}
                            alt=""
                          />
                        </Box>
                      )}
                    </Grid>
                    <Form />
                  </Grid>
                </Box>
                <Box role="tabpanel" hidden={filterData.createFormTab !== 1}>
                  <Box
                    sx={{
                      color: "#616161",
                      marginTop: "24px",
                      fontSize: "14px",
                    }}
                  >
                    Choose program you want to clone
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      marginTop: "12px",
                    }}
                  >
                    <Filters type="create" showPrograms />
                  </Box>
                  <Grid
                    container
                    spacing={3}
                    sx={{
                      marginTop: "0px",
                    }}
                  >
                    <Grid item xs={4}>
                      {filterData.programs.find(
                        (program: ProgramType) =>
                          program.id == filterData?.programValue?.id
                      ) && (
                        <Box
                          sx={{
                            background: filterData.programs.find(
                              (program: ProgramType) =>
                                program.id == filterData?.programValue?.id
                            )?.brandBackgroundColor,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "300px",
                          }}
                        >
                          <img
                            src={
                              filterData.programs.find(
                                (program: ProgramType) =>
                                  program.id == filterData?.programValue?.id
                              )?.brandLogoBlob
                            }
                            style={{ maxWidth: "100px" }}
                            alt=""
                          />
                        </Box>
                      )}
                    </Grid>

                    <Form createType="clone" />
                  </Grid>
                </Box>
                <Box
                  sx={{
                    mt: 3,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="outlined"
                    className="createProgram"
                    onClick={handleClose}
                    sx={{ mr: 2 }}
                  >
                    Cancel
                  </Button>
                  {filterData.createFormTab == 1 ? (
                    <Button
                      variant="contained"
                      className="createProgram"
                      onClick={handleClone}
                      color="primary"
                    >
                      Clone
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className="createProgram"
                      onClick={handleCreate}
                      color="primary"
                    >
                      Create
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};

export default CreateProgram;
