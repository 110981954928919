import React from 'react';
import { Box, Input } from '@mui/material';

interface ColorPickerProps {
  color: string;
  handleColorPicker: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ color, handleColorPicker }) => {
  return (
    <Box 
      className="inputSelectColor"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #C0C0C0',
        padding: '0px 8px',
        borderRadius: '4px'
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center'
        }}>
        <Input 
          name="color" 
          type="color" 
          value={color} 
          onChange={handleColorPicker} 
        />
        <Box ml={2} sx={{fontSize: "14px"}}>{color}</Box>
      </Box>
      <Box sx={{fontSize: "14px", color:"#818181"}}>100%</Box>  
    </Box>
  );
}

export default ColorPicker;
