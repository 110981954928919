import React, { ChangeEvent } from "react";
import {
  Box,
  FormControlLabel,
  Input,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import TitleForm from "./components/TitleForm";
import { StateType } from "../Preview/Product";
import AccordionForms from "./components/AccordionForms";
import Editor from "./components/Editor";
import { actions } from "../../state";
import Upload from "./components/Upload";

const Product = () => {
  const formData = useSelector((state: StateType) => state.formData);
  const { product } = formData;

  const dispatch = useDispatch();

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) =>
    dispatch(
      actions.setForm({
        ...formData,
        product: {
          ...product,
          activeTab: newValue,
        },
      })
    );

  const handleImageSave = (
    row: "row1" | "row2",
    index: number,
    value: string,
    file: any,
    type: "image" | "link" | "brand" | "flavor"
  ) => {
    let activeTab = parseInt(formData?.product?.activeTab || "0");
    let images = formData.product.stressDetails[activeTab][row].images;
    images[index] = {
      ...formData.product.stressDetails[activeTab][row].images[index],
      ...(type == "image"
        ? { src: value, upload: file }
        : {
            [type]: value,
          }),
    };

    dispatch(
      actions.setForm({
        ...formData,
        startForm: true,
        product: {
          ...formData.product,
          stressDetails: {
            ...formData.product.stressDetails,
            [activeTab]: {
              ...formData.product.stressDetails[activeTab],
              [row]: {
                ...formData.product.stressDetails[activeTab][row],
                images,
              },
            },
          },
        },
      })
    );
  };

  const handleDisplayForm = (event: ChangeEvent<HTMLInputElement>) => {
    let value = parseInt(event.target.value);
    dispatch(
      actions.setForm({
        ...formData,
        startForm: true,
        product: {
          ...product,
          display: value,
        },
        ...(formData?.product?.display != value
          ? {
              results: {
                ...formData.results,
                download: {
                  ...formData.results.download,
                  value: value == 1 ? "Product Recommendation" : "Download App",
                },
              },
            }
          : {}),
      })
    );
  };

  const handleCriteria = (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(
      actions.setForm({
        ...formData,
        startForm: true,
        product: {
          ...product,
          criteria: event.target.value,
        },
      })
    );

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <TitleForm title={product.titleForm} />
      <Box
        sx={{
          marginTop: "10px",
          paddingLeft: "16px",
          borderBottom: "1px solid #DFDFDF",
        }}
      >
        <Typography variant="caption">
          This page is optional and you can choose to not display
        </Typography>
        <RadioGroup row value={product?.display} onChange={handleDisplayForm}>
          <FormControlLabel value={1} control={<Radio />} label="Display" />
          <FormControlLabel value={0} control={<Radio />} label="Hide" />
        </RadioGroup>
      </Box>
      <Box className="scroll-form-accordion">
        <AccordionForms title="Add products">
          <Box
            sx={{
              marginTop: "-16px",
            }}
          >
            <Typography variant="caption">
              Results output driving product recommendations
            </Typography>
            <RadioGroup row value={product?.criteria} onChange={handleCriteria}>
              <FormControlLabel
                value={"MSI"}
                control={<Radio />}
                label="Stress Index"
              />
              <FormControlLabel
                value={"HYD"}
                control={<Radio />}
                label="Hydration Level"
              />
            </RadioGroup>
          </Box>
          <Tabs
            value={product?.activeTab || "0"}
            onChange={handleTabChange}
            variant="fullWidth"
          >
            <Tab
              value={"0"}
              label={product?.stressDetails[0]?.title || "Stress Index 0-2.9"}
            />
            <Tab
              value={"1"}
              label={product?.stressDetails[1]?.title || "Stress Index 3.0-5"}
            />
          </Tabs>
          <Box>
            <Typography className="subtitle-accordion">
              Add your header
            </Typography>
            <Editor
              form="product"
              field={`stressDetails.${product?.activeTab || "0"}.header`}
              rows={1}
            />
            <Box>
              <Box
                sx={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  lineHeight: "19.6px",
                  color: "#616161",
                  marginTop: "14px",
                }}
              >
                First Row
              </Box>
              <Typography className="subtitle-accordion">
                Add your description
              </Typography>
              <Editor
                form="product"
                field={`stressDetails.${
                  product?.activeTab || "0"
                }.row1.description`}
                rows={4}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                {product?.stressDetails[
                  parseInt(product?.activeTab || "0")
                ].row1.images.map((image, i) => (
                  <DragToUpload
                    data={image}
                    handleSave={(value: string, file: any) =>
                      handleImageSave("row1", i, value, file, "image")
                    }
                    onChange={(event, type) =>
                      handleImageSave(
                        "row1",
                        i,
                        event?.target?.value || "",
                        undefined,
                        type
                      )
                    }
                    key={`stressDetails${formData?.product?.activeTab}image-row1-${i}`}
                  />
                ))}
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  lineHeight: "19.6px",
                  color: "#616161",
                  marginTop: "14px",
                }}
              >
                Second row
              </Box>
              <Typography className="subtitle-accordion">
                Add your description
              </Typography>
              <Editor
                form="product"
                field={`stressDetails.${
                  product?.activeTab || "0"
                }.row2.description`}
                rows={4}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                {product?.stressDetails[
                  parseInt(product?.activeTab || "0")
                ].row2.images.map((image, i) => (
                  <DragToUpload
                    data={image}
                    handleSave={(value: string, file: any) =>
                      handleImageSave("row2", i, value, file, "image")
                    }
                    onChange={(event, type) =>
                      handleImageSave(
                        "row2",
                        i,
                        event?.target?.value || "",
                        undefined,
                        type
                      )
                    }
                    key={`stressDetails${formData?.product?.activeTab}image-row2-${i}`}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        </AccordionForms>
        <AccordionForms title="Customize your button">
          <Typography className="subtitle-accordion">Heading</Typography>
          <Editor form="product" field="help" rows={4} />
          {/* 
          <Typography className="subtitle-accordion">Primary Button</Typography>
          <Editor
            form="product"
            field="primaryButton"
            rows={1}
            format="button"
          /> */}

          <Typography className="subtitle-accordion">Button</Typography>
          <Editor
            form="product"
            field="secondaryButton"
            rows={1}
            format="button"
          />
        </AccordionForms>
        <AccordionForms title="Customize text">
          <Typography className="subtitle-accordion">Explanation</Typography>
          <Editor form="product" field="explanation" rows={4} />
        </AccordionForms>
      </Box>
    </Box>
  );
};

const DragToUpload = ({
  data,
  handleSave,
  onChange,
}: {
  data: any;
  handleSave: (value: string, file: any) => void;
  onChange: (event: any, type: "image" | "link" | "brand" | "flavor") => void;
}) => (
  <Box>
    <Upload type="image" handleSave={handleSave} hideButton small />
    <Box
      sx={{
        fontSize: "12px",
        fontFamily: "Inter",
        fontWeight: "400",
        color: "#616161",
        lineHeight: "16.8px",
        marginTop: "10px",
        marginBottom: "5px",
      }}
    >
      Attach link to your product
    </Box>
    <Input
      size="small"
      onChange={(event) => onChange(event, "link")}
      value={data?.link}
      placeholder=""
      sx={{
        fontSize: "10px",
        height: "20px",
        width: "102px",
        border: ".5px solid #C0C0C0",
        borderRadius: "4px",
        padding: "8px 5px 8px 5px",
        "&:before": {
          content: "none",
        },
        "&:after": {
          content: "none",
        },
      }}
    />
    <Input
      size="small"
      onChange={(event) => onChange(event, "brand")}
      value={data?.brand}
      placeholder=""
      sx={{
        fontSize: "10px",
        height: "20px",
        width: "102px",
        border: ".5px solid #C0C0C0",
        borderRadius: "4px",
        padding: "8px 5px 8px 5px",
        "&:before": {
          content: "none",
        },
        "&:after": {
          content: "none",
        },
      }}
    />
    <Input
      size="small"
      onChange={(event) => onChange(event, "flavor")}
      value={data?.flavor}
      placeholder=""
      sx={{
        fontSize: "10px",
        height: "20px",
        width: "102px",
        border: ".5px solid #C0C0C0",
        borderRadius: "4px",
        padding: "8px 5px 8px 5px",
        "&:before": {
          content: "none",
        },
        "&:after": {
          content: "none",
        },
      }}
    />
  </Box>
);
export default Product;
