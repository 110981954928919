import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Upload from "./components/Upload";
import Editor from "./components/Editor";
import Typography from "@mui/material/Typography";
import TitleForm from "./components/TitleForm";
import AccordionForms from "./components/AccordionForms";
import { TextType, UploadType } from "../../data/const/dataType";
import { actions } from "../../state";

interface BeginType {
  titleForm: string;
  video: any;
  button: TextType;
}

interface FormData {
  begin: BeginType;
  formActive: number;
}

type StateType = {
  formData: FormData;
  filterData: any;
};

const Begin: React.FC = () => {
  const formData = useSelector((state: StateType) => state.formData);
  const filterData = useSelector((state: StateType) => state.filterData);

  const dispatch = useDispatch();

  const handleVideoSave = (value: string, file: any) => {
    dispatch(
      actions.setForm({
        ...formData,
        startForm: true,
        begin: {
          ...formData.begin,
          video: {
            ...formData.begin.video,
            src: value,
            upload: file,
          },
        },
      })
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <TitleForm title={formData.begin.titleForm} />
      <Box
        sx={{
          overflow: "scroll",
          height: "calc(100vh - 190px)",
          paddingBottom: "100px",
        }}
      >
        <AccordionForms title="Add your video">
          <Typography className="subtitle-accordion">
            Upload your video using an external link
          </Typography>
          <Upload type="video" handleSave={handleVideoSave} />
        </AccordionForms>
        <AccordionForms title="Customize your button">
          <Typography className="subtitle-accordion">Button Text</Typography>
          <Editor form="begin" field="button" rows={1} format="button" />
        </AccordionForms>
      </Box>
    </Box>
  );
};

export default Begin;
