import React from 'react';
import Box from '@mui/material/Box';
import TitleForm from "./components/TitleForm"
import InfoIcon from '@mui/icons-material/Info';


const ScanFace: React.FC = () =>  {

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column'}}>
      <TitleForm title="Face Scan" />
      <Box className="scroll-form-accordion">
        <Box sx={{
          padding: "24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#3A3A3A",
          background: "#ffffff",
          borderRadius: "16px",
          opacity: "0.5",
        }}>
          <InfoIcon sx={{marginRight: "8px"}}/> No customization available
        </Box>
      </Box>
    </Box>
  );
}

export default ScanFace;
