import React from "react";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import Tooltip from "@mui/material/Tooltip";
import pepsico from "../data/assets/images/aspire2B-admin-logo.png";
import { actions } from "../state";
import { OKTACONFIG, OKTA_LOGOUT_URI } from "../data/const/variables";
import OktaAuth from "@okta/okta-auth-js";
import Loading from "../components/Loading";
import ErrorAPI from "../components/ErrorAPI";
import { formData as defaultFormData } from "../data/const/formsData";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

type FormDataType = {
  formActive: number;
  loading: boolean;
  apiError: boolean;
  startForm: boolean;
};

type FilterType = {
  loading: boolean;
  apiError: boolean;
  screenLoading: boolean;
};

type StateType = {
  formData: FormDataType;
  filterData: FilterType;
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const oktaAuth = new OktaAuth(OKTACONFIG);

const TopBar: React.FC = () => {
  const formData = useSelector((state: StateType) => state.formData);
  const filterData = useSelector((state: StateType) => state.filterData);

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    setAnchorEl(null);
    await oktaAuth.signOut({
      postLogoutRedirectUri: OKTA_LOGOUT_URI,
    });
  };

  const handleHomePage = () => {
    dispatch(
      actions.setForm({
        ...defaultFormData,
        formActive: 10,
      })
    );
  };

  const handleDialog = () => {
    dispatch(
      actions.setFilter({
        ...filterData,
        dialogDisplay: true,
        dialogQuestion: "Do you want to cancel your chnages?",
        dialogData: [0, "No", "Yes"],
      })
    );
  };

  return (
    <>
      <AppBar position="fixed" style={{ background: "#272727", height: 64 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px",
            height: "64px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              width: "140px",
            }}
          >
            <img
              src={pepsico}
              style={{ marginLeft: "16px", width: "60px" }}
              alt=""
            />
            {formData.formActive < 10 && (
              <HomeIcon
                onClick={handleHomePage}
                style={{
                  width: "32px",
                  height: "32px",
                  cursor: "pointer",
                  color: "#DFDFDF",
                  marginLeft: "32px",
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              color: "#EFEFEF",
              fontWeight: "700",
              marginLeft: "-120px",
            }}
          >
            Aspire2B Admin Portal
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={2}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Tooltip title="Logout">
                  <IconButton
                    onClick={handleLogout}
                    size="small"
                    sx={{ ml: 1, mr: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <LogoutIcon
                      sx={{ color: "#ffffff", width: 32, height: 32 }}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            </Stack>
          </Box>
        </Box>
        {formData.startForm && (
          <Box
            onClick={handleDialog}
            sx={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: "0px",
            }}
          ></Box>
        )}
      </AppBar>
      {filterData.loading && <Loading />}
      {filterData.screenLoading && <DiableView />}
      {filterData.apiError && <ErrorAPI />}
    </>
  );
};

const DiableView = () => (
  <Box
    onClick={() =>
      Swal.fire({
        title: "Wait",
        text: "An operation is currently underway. Please wait until it is finished before proceeding.",
        icon: "info",
        confirmButtonText: "Got it",
      })
    }
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "fixed",
      width: "100%",
      height: "100%",
      background: "transparent",
      zIndex: 999,
      opacity: "0.9",
    }}
  />
);
export default TopBar;
