import React, { useState } from "react";
import Button from "@mui/material/Button";
import dayjs, { Dayjs } from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import { actions } from "../state";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

type FilterItems = {
  id: number;
  title: string;
  enable: boolean;
  position: number;
  theme: any;
  self: boolean;
  default: boolean;
  starred: boolean;
};

type FilterData = {
  regionTitle: string;
  programTitle: string;
  localeTitle: string;
  regions: FilterItems[];
  programs: FilterItems[];
  locales: FilterItems[];
  regionPlaceholder: string;
  programPlaceholder: string;
  localePlaceholder: string;
  regionValue: {
    id: string;
    title: string;
  };
  programValue: {
    brandBackgroundColor: string;
    brandLogoBlob: string;
    default: boolean;
    id: string;
    localCode: string;
    localeName: string;
    regionCode: string;
    regionName: string;
    self: boolean;
    state: string;
    title: string;
  };
  localeValue: {
    id: string;
    title: string;
  };
  dateValue: string;
  currentProgram: any;
};

type StateType = {
  filterData: FilterData;
};

interface FiltersProps {
  type: string;
  showPrograms?: boolean;
}

const Filters: React.FC<FiltersProps> = ({ type, showPrograms }) => {
  const filterData = useSelector((state: StateType) => state.filterData);

  const dispatch = useDispatch();

  const [region, setRegion] = useState<null | HTMLElement>(null);
  const [locale, setLocale] = useState<null | HTMLElement>(null);
  const [program, setProgram] = useState<null | HTMLElement>(null);

  const openRegion = Boolean(region);
  const openLocale = Boolean(locale);
  const openProgram = Boolean(program);

  const handleRegion = (event: React.MouseEvent<HTMLButtonElement>) => {
    setRegion(event.currentTarget);
  };

  const handleLocale = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLocale(event.currentTarget);
  };

  const handleProgram = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProgram(event.currentTarget);
  };

  const handleCloseRegion = () => {
    setRegion(null);
  };

  const handleCloseLocale = () => {
    setLocale(null);
  };

  const handleCloseProgram = () => {
    setProgram(null);
  };

  const handleFilter = (type: string, id: number) => {
    handleCloseRegion();
    handleCloseLocale();
    handleCloseProgram();

    if (type === "regions") {
      const value = filterData["regions"].filter((e) => e.id === id);
      dispatch(
        actions.setFilter({
          ...filterData,
          regionValue: id ? value[0] : 0,
          programValue: 0, // reset program
          loadingFilter: true,
        })
      );
    }
    if (type === "locales") {
      const value = filterData["locales"].filter((e) => e.id === id);
      dispatch(
        actions.setFilter({
          ...filterData,
          localeValue: id ? value[0] : 0,
          programValue: 0, // reset program
          loadingFilter: true,
        })
      );
    }

    if (type == "programs") {
      const value = filterData["programs"].filter((e) => e.id === id);
      dispatch(
        actions.setFilter({
          ...filterData,
          programValue: id ? value[0] : 0,
          loadingFilter: true,
        })
      );
    }

    var regionCode = (
      type === "regions" ? id : filterData.regionValue?.id || ""
    )
      .toString()
      .replace(/\s/g, "");
    var localeCode = (
      type === "locales" ? id : filterData?.localeValue?.id || ""
    )
      .toString()
      .replace(/\s/g, "");

    dispatch(
      actions.getFilterProgrammes({
        regionCode: regionCode == "0" ? null : regionCode,
        localeCode: localeCode == "0" ? null : localeCode,
      })
    );
  };

  const handleChangeDate = (newValue: Dayjs | null) => {
    dispatch(
      actions.setFilter({
        ...filterData,
        dateValue: newValue?.format("YYYY-MM-DD"),
        loadingFilter: true,
      })
    );
    dispatch(
      actions.getFilterProgrammes({
        createdAt: newValue?.format("YYYY-MM-DD"),
      })
    );
  };

  return (
    <Box display="flex" justifyContent="left">
      <Box
        mr={2}
        sx={{ display: "flex", flexDirection: "column", alignItems: "normal" }}
      >
        {type === "filter" && (
          <Box
            sx={{
              color: "#616161",
              marginRight: "16px",
              fontSize: "14px",
            }}
          >
            {filterData.regionTitle}
          </Box>
        )}
        <Button
          onClick={handleRegion}
          endIcon={<KeyboardArrowDownIcon />}
          variant="outlined"
          sx={{
            color: filterData.regionValue ? "#616161" : "#616161",
            borderColor: filterData.regionValue ? "#dddddd" : "#dddddd",
            width: 180,
            display: "flex",
            justifyContent: "space-between",
            textTransform: "capitalize",
            fontWeight: "400",
            background: "#ffffff",
            borderRadius: "4px!important",
          }}
        >
          {filterData.regionValue?.title || filterData.regionPlaceholder}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={region}
          open={openRegion}
          onClose={handleCloseRegion}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => handleFilter("regions", 0)}>All</MenuItem>
          {filterData.regions.map((filter: FilterItems, index: number) => (
            <MenuItem
              onClick={() => handleFilter("regions", filter.id)}
              key={index}
            >
              {filter.title}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <Box
        mr={2}
        sx={{ display: "flex", flexDirection: "column", alignItems: "normal" }}
      >
        {type === "filter" && (
          <Box
            sx={{
              color: "#616161",
              marginRight: "16px",
              fontSize: "14px",
            }}
          >
            {filterData.localeTitle}
          </Box>
        )}
        <Button
          onClick={handleLocale}
          endIcon={<KeyboardArrowDownIcon />}
          variant="outlined"
          sx={{
            color: filterData.localeValue ? "#616161" : "#616161",
            borderColor: filterData.localeValue ? "#dddddd" : "#dddddd",
            width: 180,
            display: "flex",
            justifyContent: "space-between",
            textTransform: "capitalize",
            background: "#ffffff",
            fontWeight: "400",
            borderRadius: "4px!important",
          }}
        >
          {filterData.localeValue?.title || filterData.localePlaceholder}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={locale}
          open={openLocale}
          onClose={handleCloseLocale}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => handleFilter("locales", 0)}>All</MenuItem>
          {filterData.locales.map((filter: FilterItems, index: number) => (
            <MenuItem
              onClick={() => handleFilter("locales", filter.id)}
              key={index}
            >
              {filter.title}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      {showPrograms && (
        <Box
          mr={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "normal",
          }}
        >
          {type === "filter" && (
            <Box
              sx={{
                color: "#616161",
                marginRight: "16px",
                fontSize: "14px",
              }}
            >
              {filterData.programTitle}
            </Box>
          )}
          <Button
            onClick={handleProgram}
            endIcon={<KeyboardArrowDownIcon />}
            variant="outlined"
            sx={{
              color: filterData.programValue ? "#616161" : "#616161",
              borderColor: filterData.programValue ? "#dddddd" : "#dddddd",
              width: 180,
              display: "flex",
              justifyContent: "space-between",
              textTransform: "capitalize",
              background: "#ffffff",
              fontWeight: "400",
              borderRadius: "4px!important",
            }}
          >
            {filterData.programValue?.title || filterData.programPlaceholder}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={program}
            open={openProgram}
            onClose={handleCloseProgram}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {filterData.programs.map((filter: FilterItems, index: number) => (
              <MenuItem
                onClick={() => handleFilter("programs", filter.id)}
                key={index}
              >
                {filter.title}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )}
      {type === "filter" && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "normal",
          }}
        >
          <Box
            sx={{
              color: "#616161",
              marginRight: "16px",
              fontSize: "14px",
            }}
          >
            Choose Date
          </Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              value={dayjs(filterData.dateValue)}
              className="aspire-date"
              onChange={handleChangeDate}
            />
          </LocalizationProvider>
        </Box>
      )}
    </Box>
  );
};

export default Filters;
