import { formData } from "./formsData";
import dayjs from "dayjs";
/**
 * Data Header Filter
 * @type {Array}
 */

export const createDefaultProgram = {
  id: 0,
  title: "",
  enable: true,
  position: 1,
  theme: formData,
  self: false,
  default: true,
  starred: false,
  region: 0,
  regionOther: "",
  locale: 0,
  localeOther: "",
  email: "",
};

export const filterData = {
  loading: true,
  apiError: false,
  loadingFilter: false,
  openModal: false,
  loadingMessage: "Fetching",
  regionTitle: "Region",
  programTitle: "Program",
  localeTitle: "Locale",
  regionPlaceholder: "All",
  programPlaceholder: "Select",
  localePlaceholder: "All",
  regionValue: 0,
  programValue: 0,
  localeValue: 0,
  dateValue: dayjs().format("YYYY-MM-DD"),
  createFormTab: 0,
  settingFormTab: 0,
  settingFormDisplay: false,
  createProgram: createDefaultProgram,
  currentProgram: createDefaultProgram,
  loadingForm: false,
  startForm: false,
  validateForm: false,
  noticeDisplay: false,
  noticeMessage: "",
  dialogDisplay: false,
  dialogQuestion: "",
  dialogData: [0, "", ""],
  apiErrorForm: false,
  programs: [],
  regions: [],
  locales: [],
};
