import React from "react";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { TextType } from "../../data/const/dataType";
import { editorStyles, onScreenClick } from "../../data/utils/functions";
import Close from "./components/Close";
import Logo from "./components/Logo";
import defaultLogo from "../../data/assets/images/placeholder-image.png";

interface End {
  titleForm: string;
  content: TextType;
  image: any;
  button: any;
}

interface FormData {
  end: End;
  brand: any;
}

type StateType = {
  formData: FormData;
};

const End: React.FC = () => {
  const formData = useSelector((state: StateType) => state.formData);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        position: "relative",
      }}
    >
      <Close />
      <Logo />
      <Box
        sx={{
          padding: "24px 40px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            ...editorStyles(formData.end.content),
            display: "flex",
            justifyContent: "space-between",
            borderRadius: 2,
            alignItems: "center",
            padding: 4,
            background: "rgba(0,0,0,0.1)", //236,49,35
            whiteSpace: "pre-line",
          }}
        >
          {formData.end.content.value}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "16px",
            }}
          >
            <img
              src={formData.end.image.src || defaultLogo}
              width={100}
              height={120}
              alt=""
              style={{
                objectFit: "contain",
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        onClick={() => onScreenClick(2)}
        sx={{
          ...editorStyles(formData.end.button),
          borderWidth: 1,
          borderStyle: "solid",
          borderRadius: 2,
          padding: "8px 32px",
          whiteSpace: "pre-line",
        }}
      >
        {formData.end.button.value}
      </Box>
    </Box>
  );
};

export default End;
